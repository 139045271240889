import Axios from "axios";
import { getDateTime } from "./CreateDateTime_Logic";
import { data } from "jquery";
// const BASE_URL = "https://morning-bastion-23858.herokuapp.com/";
// const LOGIN = "auth";
// const PERUSAHAAN_MASTER = "perusahaanMaster";
// const PERUSAHAAN_PENGIRIM = "perusahaanPengirim"
// const PRODUK = "produk"
// const ADMIN = "admin"
// const ADMIN_KOTA = "admin/adminKota"
// const ADMIN_NOTA = "admin/adminNota"
// const UPLOAD = "upload/single"
// const SUPIR = "supir"
// const KENDARAAN = "kendaraan"
// const PENERIMA = "costumer"
// const TITIK_PENGAMBILAN = "portal";
// const LOG = "log/"
// const NOTA = "nota"
// const NOTA_PREMI = "nota/premi"
// const CHANGE_PASS = "admin/changePass/"

const handleError = (error) => {
  let errorResponse = null;
  if (error.code === "ECONNABORTED") {
    errorResponse = {
      status: 408,
      error: [{ msg: error.message }],
      message: "Request Time Out",
    };
  } else if (error.response) {
    errorResponse = error.response.data;
  } else {
    //bisa karena gada error response, etc
    errorResponse = {
      status: 501,
      error: [{ msg: "Server Implementation error." }],
    };
  }
  return errorResponse;
};

const verifyLog = async (responseData, data) => {
  if (responseData !== undefined && responseData.status === 200) {
    await createLog(
      {
        aktivitas: data.aktivitas,
        keterangan: data.keterangan,
      },
      responseData.token
    );
  }
};

export const createLog = async (data, token) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_LOG, //|| LOG,
    baseURL: process.env.REACT_APP_BASE_URL, //|| BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      keterangan: data.keterangan,
      aktivitas: data.aktivitas,
      tanggal: getDateTime(),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const hitLogin = async (username, password) => {
  var tokenData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_LOGIN, // || LOGIN,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: { "Content-Type": "application/json" },
    data: {
      username: username,
      password: password,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      tokenData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let data = {
    aktivitas: "Log In",
    keterangan: "Tidak ada keterangan",
  };
  await verifyLog(tokenData, data);
  return [tokenData, errorResponse];
};

export const upload = async (token, formData) => {
  //perlu di log?
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_UPLOAD, // || UPLOAD,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": token,
    },
    data: formData,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const submitPerusahaan = async (data, token) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_PERUSAHAAN_MASTER, // || PERUSAHAAN_MASTER,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.perusahaan,
      kota: data.kota,
      logo: data.logo,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Create Data",
    keterangan: "Membuat perusahaan cabang: " + data.perusahaan,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const submitAdmin = async (data, token) => {
  //jangan dulu dikerjain, tunggu aj minggu depan (submit gambar belum bisa)
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_ADMIN_KOTA, // || ADMIN_KOTA,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.name,
      no_hp: data.no_hp,
      email: data.email,
      nik: data.nik,
      idPerusahaan: data.perusahaan_cabang,
      kota: data.kota,
      username: data.username,
      path_uri_ktp: data.path_uri_ktp,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Create Data",
    keterangan: "Membuat Admin Kota: " + data.name,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const submitProduk = async (data, token) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_PRODUK, // || PRODUK,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      jenis: data.jenis,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Create Data",
    keterangan: "Membuat Produk: " + data.jenis,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const submitPerusahaanPengirim = async (data, token) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_PERUSAHAAN_PENGIRIM, // || PERUSAHAAN_PENGIRIM,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.nama,
      no_hp: data.no_hp,
      penanggung_jawab: data.penanggungjawab,
      alamat: data.alamat,
      kota: data.kota,
      kode_pos: data.kode_pos,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Create Data",
    keterangan: "Membuat data Perusahaan Pengiriman: " + data.nama,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const submitSupir = async (data, token) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_SUPIR, // || SUPIR,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.nama,
      no_hp: data.no_hp,
      nik: data.nik,
      no_sim: data.sim,
      path_uri_ktp: data.path_uri_nik,
      path_uri_sim: data.path_uri_sim,
      idPerusahaan: data.idPerusahaan,
      idKendaraan: data.no_kendaraan,
      idPengirim: data.transportir,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Create Data",
    keterangan: "Membuat data Supir: " + data.nama,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const submitKendaraan = async (data, token) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_KENDARAAN, // || KENDARAAN,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      jenis: data.jenis,
      kapasitas: data.kapasitas,
      no_polisi: data.no_polisi,
      // "jenis_bahan_bakar": data.jenis_bahan_bakar,
      jenis_kendaraan: data.jenis_kendaraan,
      tgl_exp: data.tgl_exp,
      path_uri_expire: data.path_uri_expire,
      idPerusahaan: data.idPerusahaan,
      idPengirim: data.perusahaan_pemilik,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Create Data",
    keterangan: "Membuat data Kendaraan: " + data.jenis,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const submitAdminNota = async (data, token) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_ADMIN_NOTA, // || ADMIN_NOTA,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.name,
      no_hp: data.no_hp,
      email: data.email,
      nik: data.nik,
      username: data.username,
      //"password": data.password,
      path_uri_ktp: data.imgPhoto,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Create Data",
    keterangan: "Membuat data Admin Nota: " + data.name,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};
export const submitPenerima = async (data, token) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_PENERIMA, // || PENERIMA,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama_perusahaan: data.nama_perusahaan,
      no_hp: data.no_hp,
      penerima: data.penerima,
      alamat: data.alamat,
      kota: data.kota,
      kode_pos: data.kode_pos,
      idPerusahaan: data.idPerusahaan,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Create Data",
    keterangan:
      "Membuat data Perusahaan Penerima (Customer): " + data.nama_perusahaan,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const submitTitikPengambilan = async (data, token) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "post",
    url: process.env.REACT_APP_TITIK_PENGAMBILAN, // || TITIK_PENGAMBILAN,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.pengambilan_barang,
      keterangan: "default",
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Create Data",
    keterangan: "Membuat data Portal: " + data.pengambilan_barang,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const editPerusahaan = async (data, token, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "patch",
    url: process.env.REACT_APP_PERUSAHAAN_MASTER + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.perusahaan,
      kota: data.kota,
      logo: data.logo,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Edit Data",
    keterangan: "Mengubah data Perusahaan Cabang: " + data.perusahaan,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const editAdmin = async (data, token, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "patch",
    url: process.env.REACT_APP_ADMIN + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.name,
      no_hp: data.no_hp,
      email: data.email,
      nik: data.nik,
      idPerusahaan: data.perusahaan_cabang,
      kota: data.kota,
      username: data.username,
      password: data.password || "",
      admin_level: data.admin_level,
      path_uri_ktp: data.path_uri_ktp || data.imgPhoto,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Edit Data",
    keterangan: "Mengubah data Admin Kota: " + data.name,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const editPerusahaanPengirim = async (data, token, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "patch",
    url: process.env.REACT_APP_PERUSAHAAN_PENGIRIM + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.nama,
      no_hp: data.no_hp,
      penanggung_jawab: data.penanggungjawab,
      alamat: data.alamat,
      kota: data.kota,
      kode_pos: data.kode_pos,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Edit Data",
    keterangan: "Mengubah data Perusahaan Pengiriman: " + data.nama,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const editSupir = async (data, token, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "patch",
    url: process.env.REACT_APP_SUPIR + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.nama,
      no_hp: data.no_hp,
      nik: data.nik,
      no_sim: data.sim,
      path_uri_ktp: data.path_uri_nik,
      path_uri_sim: data.path_uri_sim,
      idPengirim: data.transportir,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Edit Data",
    keterangan: "Mengubah data Supir: " + data.nama,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const editKendaraan = async (data, token, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "patch",
    url: process.env.REACT_APP_KENDARAAN + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      jenis: data.jenis,
      kapasitas: data.kapasitas,
      no_polisi: data.no_polisi,
      jenis_kendaraan: data.jenis_kendaraan,
      tgl_exp: data.tgl_exp,
      path_uri_expire: data.path_uri_expire,
      idPengirim: data.perusahaan_pemilik,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Edit Data",
    keterangan: "Mengubah data Kendaraan: " + data.jenis,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const editPenerima = async (data, token, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "patch",
    url: process.env.REACT_APP_PENERIMA + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama_perusahaan: data.nama_perusahaan,
      no_hp: data.no_hp,
      penerima: data.penerima,
      alamat: data.alamat,
      kota: data.kota,
      kode_pos: data.kode_pos,
      idPerusahaan: data.idPerusahaan,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Edit Data",
    keterangan:
      "Mengubah data Perusahaan Penerima (Customer): " + data.nama_perusahaan,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const editTitikPengambilan = async (data, token, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "patch",
    url: process.env.REACT_APP_TITIK_PENGAMBILAN + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      nama: data.pengambilan_barang,
      keterangan: "default",
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Edit Data",
    keterangan: "Mengubah data Portal: " + data.pengambilan_barang,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const submitNota = async (token, value, edit, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: edit ? "patch" : "post",
    url: edit
      ? process.env.REACT_APP_NOTA + "/update/" + id
      : process.env.REACT_APP_NOTA, // || NOTA,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      no_nota: value.no_nota,
      no_po: value.no_po,
      tanggal_pengirim: value.tgl_pengiriman,
      asal_pengiriman: value.perusahaan_pengirim,
      tujuan_pengiriman: value.customer,
      titik_pengambilan: value.portal,
      jenis_produk: value.produk,
      harga_produk: value.harga,
      kuantitas: value.kuantitas,
      no_segel_atas: value.no_segel_atas,
      no_segel_bawah: value.no_segel_bawah,
      no_segel_atas_2: value.no_segel_atas_2,
      no_segel_bawah_2: value.no_segel_bawah_2,
      kendaraanId: value.no_kendaraan,
      nama_kendaraan: value.kendaraan_lain || value.kendaraan,
      perusahaan_pemiliki_kendaraan:
        value.perusahaan_pemilik_lain || value.perusahaan_pemilik,
      no_polisi: value.no_kendaraan_lain || value.no_polisi,
      supirId: value.supir,
      nama_supir: value.supir_lain || value.nama_supir,
      no_hp_supir: value.no_hp || value.no_hp_ori,
      biaya_premi: value.premi,
      catatan: value.catatan,
      keterangan: "tidak ada keterangan",
      status: value.status,
      biaya_penyebrangan: value.biaya_portal || "", //opsional
      pj_pengirim: value.penanggung_jawab,
      pj_penerima: value.customer_pic,
      nama_perusahaan_tujuan: value.nama_perusahaan_tujuan,
      alamat_perusahaan_tujuan: value.alamat_perusahaan_tujuan,
      site: value.site,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Create Data",
    keterangan: "Membuat data Nota Pengiriman No. " + value.no_nota,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const editStatusNota = async (
  token,
  id,
  keterangan,
  pemakaian,
  no_nota
) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "patch",
    url: process.env.REACT_APP_NOTA + "/status/" + id,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      keterangan: keterangan,
      liter: pemakaian,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Edit Data",
    keterangan: "Mengubah status Nota Pengiriman No. " + no_nota,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const editStatusPremi = async (token, id, no_nota) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "patch",
    url: process.env.REACT_APP_NOTA_PREMI + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Edit Data",
    keterangan:
      "Mengubah data Status Premi dari Nota Pengiriman No. " + no_nota,
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};

export const submitChangePass = async (token, data) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "patch",
    url: process.env.REACT_APP_CHANGE_PASS, // || CHANGE_PASS,
    baseURL: process.env.REACT_APP_BASE_URL, // || BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
    data: {
      old_password: data.oldpassword,
      new_password: data.newpassword,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi.",
  })
    .then(function (response) {
      responseData = response.data;
    })
    .catch(function (error) {
      errorResponse = handleError(error);
    });
  let resp = {
    aktivitas: "Change Password",
    keterangan: "Mengubah password pribadi admin",
  };
  await verifyLog(responseData, resp);
  return [responseData, errorResponse];
};
