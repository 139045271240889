import { Button, Form, Col, Spinner} from "react-bootstrap";
import React, { Component, useEffect, useState} from "react";
import {useForm, ErrorMessage} from "react-hook-form";
import Kota from "../data/Data"
import AutoComplete from "./AutoComplete_Com"
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
import {handleTextInput, handleNumberInput, condensingWhitespace} from '../logics/FormInput_Logic';
import { submitPenerima, editPenerima } from "../logics/SubmitForm_Logic";
import { useAuth } from "../context/Auth_Context"

function FormPenerimaFunCom(props){
    const {register, handleSubmit, errors, setValue, getValues} = useForm({
        mode:"onChange",
        defaultValues: {
            nama_perusahaan: props.location.state ? props.location.state.nama_perusahaan : "",
            penerima: props.location.state ? props.location.state.penerima : "",
            no_hp: props.location.state ? props.location.state.no_hp : "",
            alamat: props.location.state ? props.location.state.alamat : "",
            kota: props.location.state ? props.location.state.kota : "",
            kode_pos: props.location.state ? props.location.state.kode_pos : "",
            id: props.location.state ? props.location.state._id : "",
            state: props.location.state ? "edit" : "input"
        }
    });

    const [loading,setLoading] = useState(false);
    const { auth,setToken } = useAuth();

    const childSetValue = (name,value) => {
        setValue(name,value);
    }

    const handleChange = event => {
        let val = event.target.value
        if(event.target.name === "no_hp" || event.target.name === "kode_pos") val = handleNumberInput(event) 
        else val = handleTextInput(event.target.name === "nama_perusahaan" ? "perusahaan" : event.target.name,event);
        setValue(event.target.name,val);
    }

    const onSubmit = async value => {
        setLoading(true);
        value.idPerusahaan = auth.idPerusahaan;
        let success = false
        let status = [];
        let message = [];
        value = condensingWhitespace(value)
        let response = getValues("state") === "edit" ? await editPenerima(value,auth.token,getValues("id")) : await submitPenerima(value,auth.token);
        if(response[0] && response[0].status===200){
            response[0].token && setToken(response[0].token)
            success = true;
        }  else if (response[0] && response[0].status === 402) {
            let path = "/";
            this.props.history.push({
                pathname: path,
                state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
            })
        } else {
            if(response[1]) {
                response[1].error.map((item)=>{
                    status = status.concat(1);
                    message = message.concat("Status: "+response[1].status+" - "+response[1].message + ". " + item.msg);
                })
            }
            else {
                status = status.concat(1);
                message = message.concat("Status: "+response[0].status+" - "+response[0].message + ". " + response[0].error[0].msg);
            }
        }
        setLoading(false);
        if(success){
            props.showModal(getValues("state"),getValues("state")==="edit"?getValues("nama_perusahaan"):response[0].data[0].nama_perusahaan);
        } else {
            let idx = 0;
            status.map(function(item){
                props.generateToast(item,message[idx]);
                idx = idx + 1;
            })
        }
    }
    return(
        <div className="container">
            {getValues("state") === "edit" && <h4>Sedang mengedit Customer: {props.location.state.nama_perusahaan}</h4>} 
            <Form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                            <Form.Label >Nama Perusahaan / Customer :</Form.Label>
                            <Form.Control 
                                className={errors.nama_perusahaan && "invalid"}
                                name="nama_perusahaan" 
                                type="text" 
                                //value={this.state.perusahaan} 
                                ref={register({required: "Input tidak boleh kosong"})}
                                onChange={handleChange} />
                        <ErrorMessage errors={errors} name="nama_perusahaan">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>                            
                </Form.Group>                
                <Form.Group>
                            <Form.Label >Nama Penerima di Lokasi : <em> (Opsional)</em></Form.Label>
                            <Form.Control 
                                className={errors.penerima && "invalid"}
                                name="penerima" 
                                type="text"
                                ref={register()}
                                onChange={handleChange}
                                />
                        <ErrorMessage errors={errors} name="penerima">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>                             
                </Form.Group>                
                <Form.Group>
                            <Form.Label >No HP Penerima : <em> (Opsional)</em></Form.Label>
                            <Form.Control 
                                className={errors.no_hp && "invalid"}    
                                name="no_hp" 
                                type="text"
                                onChange={handleChange}
                                ref={register()}
                                />
                        <ErrorMessage errors={errors} name="no_hp">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>                                
                </Form.Group>                
                <Form.Group>
                            <Form.Label >Alamat Kantor / Perusahaan Customer :</Form.Label>
                            <Form.Control 
                                className={errors.alamat && "invalid"}    
                                name="alamat" 
                                type="text"
                                onChange={handleChange}
                                ref={register({required: "Input tidak boleh kosong"})}
                                />
                        <ErrorMessage errors={errors} name="alamat">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>                                
                </Form.Group>
                <Form.Row>                  
                    <Form.Group as={Col}> 
                                <Form.Label >Kota :</Form.Label>
                                {/* <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    onChange={setSingleSelections}
                                    options={Kota}
                                    selected={singleSelections}
                                    reference={register({ required: "Input tidak boleh kosong" })}
                                    setValue={childSetValue}
                                    errors={errors}
                                /> */}
                                <AutoComplete
                                    suggestions={Kota}
                                    reference={register({ required: "Input tidak boleh kosong" })}
                                    setValue={childSetValue}
                                    errors={errors}
                                />
                            <ErrorMessage errors={errors} name="kota">
                                {({ message }) => <p className="error_info">{message}</p>}
                            </ErrorMessage>                 
                    </Form.Group>
                    <Form.Group as={Col}>
                                <Form.Label >Kode Pos :</Form.Label>
                                <Form.Control 
                                    className={errors.kode_pos && "invalid"}   
                                    name="kode_pos"
                                    type="text" 
                                    onChange={handleChange} 
                                    ref={register({
                                        required: "Input tidak boleh kosong",
                                        maxLength: {
                                            value: 5,
                                            message: "Input harus 5 digit"
                                        },
                                        minLength: {
                                            value: 5,
                                            message: "Input harus 5 digit"
                                        }
                                    })}
                                    />
                            <ErrorMessage errors={errors} name="kode_pos">
                                {({ message }) => <p className="error_info">{message}</p>}
                            </ErrorMessage>                               
                    </Form.Group>                
                </Form.Row>               
                <div className="btn-container">
                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? <Spinner animation="border" variant="secondary"/>: "Save"}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

class FormPenerimaCom extends Component{
    constructor(props){
        super(props);
    }

    render(){return <FormPenerimaFunCom {...this.props}/> }
}

export default FormPenerimaCom;