import React, { Component } from "react";
import SideBar from "../components/SideBar_Com";
import Table from "../components/Table_Com"; 
import '../styles/Table_Style.css'

class TablePengirimanKota extends Component {
    render() {
        return (
            <div className="home">
                <SideBar/>
                <div className="main">
                    <div className="container-table">
                        <Table status={"TablePengirimanKota"}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default TablePengirimanKota;