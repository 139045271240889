import React, { Component } from "react";
import FormPengirimanKotaCom from "../components/FormPengirimanKota_Com";
import SideBar from "../components/SideBar_Com";

class FormPengirimanKota extends Component {
    render() {
        return (
            <div className="home">
                <SideBar/>
                <div className="main">
                    <FormPengirimanKotaCom/>
                </div>
            </div>
        );
    }
}

export default FormPengirimanKota;