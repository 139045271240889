import React, { Component } from "react";
import { Modal, Button, Spinner, Image, Form, Row, Col, InputGroup } from "react-bootstrap";
import SideBar from "../components/SideBar_Com";
import Table from "../components/Table_Com";
import { deleteAdmin } from "../logics/DeleteData_Logic"
import { AuthContext } from "../context/Auth_Context"
import { fetchAdmin } from "../logics/FetchData_Logic"
import '../styles/Table_Style.css'
import Timeout from "./Timeout_Page";
import ModalLoading from "../components/ModalLoading_Com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

class TableAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            showModalDelete: false,
            showModalPreview: false,
            delData: null,
            loading: false,
            successDel: null,
            gmcode: "prima3232",
            codeField: null,
            url_gambar: "assets/user.svg",
            timeout: false,
            mainLoading: true
        }
    }

    preview = (obj, type) => {
        this.setState({
            showModalPreview: true,
            url_gambar: obj
        })
    }

    async componentDidMount() {
        const { auth, setToken } = this.context;
        let timeout = false;
        let token = auth.token;
        let obj = await fetchAdmin(token);
        let data_table = null;
        if (obj[0] !== undefined) {
            if (obj[0].status === 402) {
                let path = "/";
                this.props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            } else if (obj[0].status === 200) {
                data_table = {
                    data: obj[0].data,
                    searching: false,
                    scrollY: 341,
                    scrollCollapse: true,
                    columns: [
                        { data: "nama" },
                        {
                            data: "idPerusahaan",
                            render: function (data) {
                                if (data === "~") return data
                                return data ? data.nama : "-"
                            }
                        },
                        { data: "email" },
                        { data: "no_hp" },
                        { data: "nik" },
                        {
                            data: "path_uri_ktp",
                            orderable: false,
                            render: function (data, type, row, meta) {
                                if (data === "~") return data;
                                return '<div class="icon_container"><img class="icon nik" key="' + data + '" src="assets/user.svg"/></div>';
                            }

                        },
                        {
                            data: "empty",
                            className: "center",
                            orderable: false,
                            defaultContent: '<div class="icon_container"><img class="icon edit" src="assets/edit.svg"/><img class="icon delete" src="assets/trash.svg"/></div>'
                        }
                    ],
                    columnDefs: [
                        {
                            targets: [0,1,2,3],
                            type: "text"
                        },
                        {
                            targets: 4,
                            type: "number"
                        }
                    ]
                }
                if (obj[0].token) setToken(obj[0].token);
            } else { //error?

            }
        } else if (obj[1].status && obj[1].status === 408) {
            timeout = true
        } else {
            this.props.history.push("/500ServerError")
        }
        this.setState({
            data: data_table,
            mainLoading: false
        })
    }

    confirmation = (data) => {
        this.setState({
            delData: data,
            showModalDelete: true
        })
    }

    handleClose = () => {
        if (this.state.successDel) {
            window.location.reload();
        }
        else {
            this.setState({
                showModalDelete: false,
                showModalPreview: false
            })
        }
    }

    delete = async () => { // belum jalan
        this.setState({
            loading: true
        })
        const { auth, setToken } = this.context;
        let token = auth.token;
        let success = false;
        let response = await deleteAdmin(token, this.state.delData._id, "Kota", this.state.delData.nama)
        if (response[0] !== undefined) {
            if (response[0].status === 200) {
                success = true;
                if (response[0].token) setToken(response[0].token);
            } else if (response[0].status === 402) {
                let path = "/";
                this.props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            }
        }
        this.setState({
            loading: false,
            successDel: success
        })
    }

    render() {
        return (
            <div className="home">
                <Modal backdrop="static" show={this.state.showModalDelete} onHide={this.handleClose}>
                    <Modal.Header closeButton={!this.state.loading}>
                        <Modal.Title>Konfirmasi Hapus</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.successDel === null && this.state.delData ? (
                                <>
                                    <p>Apakah anda yakin untuk menghapus data berikut:</p>
                                    <ul>
                                        <li>Nama: {this.state.delData.nama}</li>
                                        <li>Perusahaan: {this.state.delData.idPerusahaan.nama}</li>
                                        <li>E-mail: {this.state.delData.email}</li>
                                        <li>No. HP: {this.state.delData.no_hp}</li>
                                        <li>NIK: {this.state.delData.nik}</li>
                                    </ul>
                                    <br />
                                    <p>Untuk menghapus silahkan masukan Kode GrandMaster:</p>
                                    <Form>
                                    <Row className="justify-content-md-center">
                                        <Col md="6">
                                        <Form.Group>
                                            <InputGroup>
                                            <Form.Control
                                                className="input-Password"
                                                type={this.state.hidden ? "text" : "password"}
                                                onChange={code =>
                                                this.setState({
                                                    codeField: code.target.value
                                                })
                                                }
                                                disabled={this.state.loading}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text>
                                                {!this.state.hidden ? (
                                                    <FontAwesomeIcon
                                                    onClick={() => {
                                                        this.toggleShowPassword();
                                                    }}
                                                    icon={faEyeSlash}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                    onClick={() => {
                                                        this.toggleShowPassword();
                                                    }}
                                                    icon={faEye}
                                                    />
                                                )}
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                            </InputGroup>
                                            {/* <Form.Control 
                                            type="text"
                                            placeholder="Kode GrandMaster"
                                            onChange={(code) => this.setState({
                                                codeField: code.target.value
                                            })}
                                            /> */}
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    </Form>
                                </>
                            ) :
                                (
                                    this.state.successDel ?
                                        <p>Rekord berhasil dihapus</p>
                                        :
                                        <p>Error, terjadi kesalahan saat ingin menghapus rekord</p>
                                )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.successDel !== null ?
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Close
                                </Button>
                                : (this.state.loading ?
                                    <Button variant="danger" disabled>
                                        <Spinner animation="border" variant="secondary" />
                                    </Button>
                                    :
                                    <>
                                        <Button variant="secondary" onClick={this.handleClose}>
                                            Close
                            </Button>
                                        <Button
                                            variant="danger"
                                            onClick={this.delete}
                                            style={{
                                                cursor: this.state.codeField !== this.state.gmcode ?
                                                    "default" : "pointer"
                                            }}
                                            disabled={ this.state.codeField !== this.state.gmcode ?
                                                true : false
                                            }
                                        >
                                            Delete
                            </Button>
                                    </>)
                        }
                    </Modal.Footer>
                </Modal>
                <Modal backdrop="static" show={this.state.showModalPreview} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preview NIK</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Image src={this.state.url_gambar} thumbnail />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                {!this.state.timeout && <SideBar />}
                <div className="main">
                    {this.state.mainLoading && <ModalLoading />}
                    {this.state.timeout && <Timeout {...this.props} />}
                    <div className="container-table">
                        {this.state.data && <Table {...this.props} delete={this.confirmation} preview={this.preview} status={"TableAdmin"} data={this.state.data} />}
                    </div>
                </div>
            </div>
        );
    }
}

TableAdmin.contextType = AuthContext
export default TableAdmin;