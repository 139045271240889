import React, { Component } from "react";
import { Modal, Button, Spinner, Image } from "react-bootstrap";
import Table from "../components/Table_Com";
import '../styles/Table_Style.css'
import SideBar from "../components/SideBar_Com";
import { fetchSupir } from "../logics/FetchData_Logic";
import { deleteSupir } from "../logics/DeleteData_Logic";
import { AuthContext } from "../context/Auth_Context";
import Timeout from "./Timeout_Page";
import ModalLoading from "../components/ModalLoading_Com"

class TableSupir extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            level: "",
            showModalDelete: false,
            showModalPreview: false,
            delData: null,
            loading: false,
            successDel: null,
            url_gambar: "assets/user.svg",
            type: "",
            timeout: false,
            mainLoading: true
        }
    }

    preview = (obj, type) => {
        this.setState({
            showModalPreview: true,
            url_gambar: obj,
            type: type.toUpperCase()
        })
    }

    async componentDidMount() {
        const { auth, setToken } = this.context;
        let token = auth.token;
        let obj = await fetchSupir(token);
        let data_table = null;
        let timeout = false;
        if (obj[0] !== undefined) {
            if (obj[0].status === 402) {
                let path = "/";
                this.props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            } else if (obj[0].status === 200) {
                data_table = {
                    data: obj[0].data.supir,
                    scrollY: 341,
                    scrollCollapse: true,
                    searching: false,
                    columns: [
                        { data: "nama" },
                        { 
                            data: "no_hp",
                            render: (data) => {
                                if(!data) return "-"
                                return data
                            }
                        },
                        { data: "no_sim" },
                        { data: "nik" },
                        { data: "idKendaraan.no_polisi" },
                        {
                            data: "path_uri_sim",
                            orderable: false,
                            render: function (data, type, row, meta) {
                                if (data === "~") return data;
                                else return '<div class="icon_container"><img class="icon sim" key="' + data + '" src="assets/user.svg"/></div>';
                            }
                        },
                        {
                            data: "path_uri_ktp",
                            orderable: false,
                            render: function (data, type, row, meta) {
                                if (data === "~") return data;
                                else return '<div class="icon_container"><img class="icon nik" key="' + data + '" src="assets/user.svg"/></div>';
                            }
                        },
                        {
                            data: "preview_premi",
                            orderable: false,
                            defaultContent: '<div class="icon_container"><img class="icon prev_premi" src="assets/database.svg"/></div>'
                        }
                    ],
                    columnDefs: [
                        {
                            targets: [0,1,2,3],
                            type: "text"
                        }
                    ],
                }

                auth.level === "2" && (data_table.columns = data_table.columns.concat({
                    data: "empty",
                    orderable: false,
                    className: "center",
                    defaultContent: '<div class="icon_container"><img class="icon edit" src="assets/edit.svg"/><img class="icon delete" src="assets/trash.svg"/></div>'
                }))

                if (obj[0].token) setToken(obj[0].token);
            } else {
                //kalau status nya tidak diantara itu berdua? error server?

            }
        } else if (obj[1].status && obj[1].status === 408) {
            timeout = true
        } else {
            this.props.history.push("/500ServerError")
        }
        this.setState({
            data: data_table,
            level: auth.level,
            timeout: timeout,
            mainLoading: false
        })
    }

    confirmation = (data) => {
        this.setState({
            delData: data,
            showModalDelete: true
        })
    }

    handleClose = () => {
        if (this.state.successDel) {
            window.location.reload();
        }
        else {
            this.setState({
                showModalDelete: false,
                showModalPreview: false
            })
        }
    }

    delete = async () => { // belum jalan
        this.setState({
            loading: true
        })
        const { auth, setToken } = this.context;
        let token = auth.token;
        let success = false;
        let response = await deleteSupir(token, this.state.delData._id, this.state.delData.nama)
        if (response[0] !== undefined) {
            if (response[0].status === 200) {
                success = true;
                if (response[0].token) setToken(response[0].token);
            } else if (response[0].status === 402) {
                let path = "/";
                this.props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            }
        }
        this.setState({
            loading: false,
            successDel: success
        })
    }

    render() {
        return (
            <div className="home">
                <Modal backdrop="static" show={this.state.showModalDelete} onHide={this.handleClose}>
                    <Modal.Header closeButton={!this.state.loading}>
                        <Modal.Title>Konfirmasi Hapus</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.successDel === null && this.state.delData ? (
                                <>
                                    <p>Apakah anda yakin untuk menghapus data berikut:</p>
                                    <ul>
                                        <li>Nama: {this.state.delData.nama}</li>
                                        <li>No. HP: {this.state.delData.no_hp}</li>
                                        <li>SIM: {this.state.delData.no_sim}</li>
                                        <li>NIK: {this.state.delData.nik}</li>
                                    </ul>
                                </>
                            ) :
                                (
                                    this.state.successDel ?
                                        <p>Rekord berhasil dihapus</p>
                                        :
                                        <p>Error, terjadi kesalahan saat ingin menghapus rekord</p>
                                )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.successDel !== null ?
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Close
                                </Button>
                                : (this.state.loading ?
                                    <Button variant="danger" disabled>
                                        <Spinner animation="border" variant="secondary" />
                                    </Button>
                                    :
                                    <>
                                        <Button variant="secondary" onClick={this.handleClose}>
                                            Close
                            </Button>
                                        <Button variant="danger" onClick={this.delete}>
                                            Delete
                            </Button>
                                    </>)
                        }
                    </Modal.Footer>
                </Modal>
                <Modal backdrop="static" show={this.state.showModalPreview} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preview {this.state.type}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Image src={this.state.url_gambar} thumbnail />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                {!this.state.timeout && <SideBar />}
                <div className="main">
                    {this.state.mainLoading && <ModalLoading />}
                    {this.state.timeout && <Timeout {...this.props} />}
                    <div className="container-table">
                        {this.state.data && <Table {...this.props} delete={this.confirmation} preview={this.preview} status={"TableSupir"} data={this.state.data} level={this.state.level} />}
                    </div>
                </div>
            </div>
        );
    }
}

TableSupir.contextType = AuthContext
export default TableSupir;