import React, { Component } from "react";
import FormSupirCom from "../components/FormSupir_Com";
import SideBar from "../components/SideBar_Com";
import { Modal, Button, Toast } from "react-bootstrap";
import { getTime } from "../logics/CreateDateTime_Logic";
import { AuthContext } from "../context/Auth_Context";
import { fetchKendaraan, fetchPerusahaanPengiriman } from "../logics/FetchData_Logic";
import ModalLoading from "../components/ModalLoading_Com";
import Timeout from "./Timeout_Page";

class FormSupir extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: [],
      toast_data: [],
      showModal: false,
      modalData: {},
      data_kendaraan: null,
      data_transportir: null,
      edit: null,
      timeout: false,
      loading: false,
    };
  }

  close(index, pos) {
    //destroy
    let show_arr = [...this.state.showToast];
    show_arr[index] = false;
    this.setState(
      {
        showToast: show_arr,
      },
      this.destroy(pos)
    );
  }

  destroy(position) {
    //nge rerender ketika masih ada yg di show apakah ga bakal masalah?
    let toast_data = [...this.state.toast_data];
    toast_data.splice(position, 1, null);
    this.setState({
      toast_data: toast_data,
    });
  }

  move = () => {
    this.props.history.push("/TableSupir");
  };

  showModal = (type, name) => {
    this.setState({
      showModal: true,
      modalData: {
        name: name,
        type: type,
      },
    });
  };

  generateToast = (status, message) => {
    let idx = this.state.showToast.length;
    let header = "";
    let header_class = "";
    switch (status) {
      case 0: //success
        header_class = "success";
        header = "Success!!";
        break;
      case 1: //error from input
        header_class = "warning";
        header = "Error!!";
        break;
      case 2: //error from server
        header_class = "danger";
        header = "Error!!";
        break;
      default:
        header_class = "";
        header = "Unknown";
        break;
    }
    let data = {
      class: header_class,
      header: header,
      msg: message,
      idx: idx,
      pos: this.state.toast_data.length,
    };
    this.setState({
      toast_data: this.state.toast_data.concat(data),
      showToast: this.state.showToast.concat(true),
    });
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    const { auth, setToken } = this.context;
    let obj = await fetchKendaraan(auth.token);
    let obj_2 = await fetchPerusahaanPengiriman(auth.token);
    let data = null;
    let data_transportir = null;
    let dataEdit = null;
    let timeout = false;
    if (obj[0] !== undefined && obj_2[0] !== undefined) {
      if (obj[0].status === 200 && obj_2[0].status === 200) {
        data_transportir = obj_2[0].data
        console.log(data_transportir)
        data = obj[0].data.kendaraan;
        if (obj[0].token) setToken(obj[0].token);
        if (this.props.location.state) {
          dataEdit = { ...this.props.location.state };
          let isExist = false;
          for (let i = 0; i < obj[0].data.kendaraan.length; i++) {
            if (obj[0].data.kendaraan[i]._id === dataEdit.idKendaraan._id) {
              isExist = true;
              break;
            }
          }
          if (!isExist) dataEdit.idKendaraan._id = "";
        }
      } else if (obj[0].status === 402 || obj_2[0].status === 402) {
        let path = "/";
        this.props.history.push({
          pathname: path,
          state:
            "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi.",
        });
        return;
      }
    } else if ((obj[1].status && obj[1].status === 408) || (obj_2[1].status && obj_2[1].status === 408)) {
      timeout = true;
    } else {
      this.props.history.push("/500ServerError");
      return;
    }
    this.setState({
      data_kendaraan: data,
      data_transportir: data_transportir,
      timeout: timeout,
      loading: false,
      edit: dataEdit,
    });
  }

  render() {
    return (
      <div className="home">
        {!this.state.timeout && <SideBar activeClassName="active-link" />}
        <div className="toast_container">
          {this.state.toast_data.map((item) => {
            return (
              item !== null && (
                <Toast
                  animation
                  className={item.class || "unknown"}
                  show={this.state.showToast[item.idx]}
                  onClose={() => this.close(item.idx, item.pos)}
                  autohide
                  delay={5000}
                >
                  <Toast.Header>
                    <strong className={"mr-auto "}>
                      {item.header || "Unknown"}
                    </strong>
                    <small>{getTime()}</small>
                  </Toast.Header>
                  <Toast.Body>
                    {item.msg ||
                      "Some undocummented error occured. Please contact the administrator to look at this problem"}
                  </Toast.Body>
                </Toast>
              )
            );
          })}
          <Modal
            show={this.state.showModal}
            onHide={this.move}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Sukses!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Data supir {this.state.modalData.name} telah berhasil{" "}
                {this.state.modalData.type === "input"
                  ? "didaftarkan"
                  : "diupdate"}
                .
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.move}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {this.state.loading && <ModalLoading />}
        {this.state.timeout && <Timeout />}
        {this.state.data_kendaraan && this.state.data_transportir && (
          <div className="main">
            <FormSupirCom
              generateToast={this.generateToast}
              kendaraan={this.state.data_kendaraan}
              transportir={this.state.data_transportir}
              showModal={this.showModal}
              edit={this.state.edit}
              {...this.props}
            />
          </div>
        )}
      </div>
    );
  }
}

FormSupir.contextType = AuthContext;
export default FormSupir;
