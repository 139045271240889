import React, { Component } from "react";
import { Modal, Toast, Button } from "react-bootstrap";
import FormNotaCom from "../components/FormNota_Com";
import SideBar from "../components/SideBar_Com";
import { getTime } from "../logics/CreateDateTime_Logic";
import { AuthContext } from "../context/Auth_Context";
import {
  fetchNomorNota,
  fetchNotaData,
  fetchSpecificPengiriman,
} from "../logics/FetchData_Logic";
import Timeout from "./Timeout_Page";
import ModalLoading from "../components/ModalLoading_Com";

class FormNota extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: [],
      toast_data: [],
      showModal: false,
      //fetchNomor: null,
      notaData: null,
      fetchProgress: 0,
      fetchMessage: "Sedang mengambil nomor nota terbaru",
      modalData: {},
      timeout: false,
      mainLoading: true,
      isChanged: false,
    };

    this.totalProgress = 7;
  }

  close(index, pos) {
    //destroy
    let show_arr = [...this.state.showToast];
    show_arr[index] = false;
    this.setState(
      {
        showToast: show_arr,
      },
      this.destroy(pos)
    );
  }

  destroy(position) {
    //nge rerender ketika masih ada yg di show apakah ga bakal masalah?
    let toast_data = [...this.state.toast_data];
    toast_data.splice(position, 1, null);
    this.setState({
      toast_data: toast_data,
    });
  }

  move = () => {
    this.props.history.push("/TablePengirimanPerusahaan");
  };

  showModal = (data, edit) => {
    this.setState({
      showModal: true,
      modalData: {
        changed: data.changed,
        no_nota: data.to,
        edit: edit,
      },
    });
  };

  generateToast = (status, message) => {
    let idx = this.state.showToast.length;
    let header = "";
    let header_class = "";
    switch (status) {
      case 0: //success
        header_class = "success";
        header = "Success!!";
        break;
      case 1: //error from input
        header_class = "warning";
        header = "Error!!";
        break;
      case 2: //error from server
        header_class = "danger";
        header = "Error!!";
        break;
      default:
        header_class = "";
        header = "Unknown";
        break;
    }
    let data = {
      class: header_class,
      header: header,
      msg: message,
      idx: idx,
      pos: this.state.toast_data.length,
    };
    this.setState({
      toast_data: this.state.toast_data.concat(data),
      showToast: this.state.showToast.concat(true),
    });
  };

  // async componentDidMount(){
  //     const {auth, setToken } = this.context
  //     let response = await fetchNomorNota(auth.token);
  //     let timeout = false;
  //     let fetchNomor = null;
  //     if(response[0] !== undefined){
  //         if(response[0].status===200){
  //             fetchNomor = response[0].data[0].no_nota;
  //             if (response[0].token) setToken(response[0].token);
  //         } else if(response[0].status === 402) {
  //             let path = "/";
  //             this.props.history.push({
  //                 pathname: path,
  //                 state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
  //             })
  //         } else { //error?

  //         }
  //     } else if (response[1].status && response[1].status === 408) {
  //         timeout = true
  //     } else {
  //         this.props.history.push("/500ServerError")
  //     }
  //     this.setState({
  //         fetchNomor: fetchNomor,
  //         timeout: timeout,
  //         mainLoading: false
  //     })
  // }

  updateProgressFetch = (msg) =>
    this.setState({
      fetchProgress: this.state.fetchProgress + 1,
      fetchMessage: msg,
    });
  error = () => this.props.history.push("/500ServerError");
  timeOut = () => this.setState({ timeout: true, mainLoading: false });
  relogin = () =>
    this.props.history.push({
      pathname: "/",
      state:
        "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi.",
    });

  async componentDidMount() {
    const { auth, setToken } = this.context;
    let response = await fetchNotaData(
      auth.token,
      this.updateProgressFetch,
      this.error,
      this.relogin,
      this.timeOut
    );
    if (response) {
      setToken(response.token);
      this.setState({
        mainLoading: false,
        notaData: response,
      });
    } else return;
  }

  render() {
    return (
      <div className="home">
        {!this.state.timeout && <SideBar />}
        <div className="toast_container">
          {this.state.toast_data.map((item) => {
            return (
              item !== null && (
                <Toast
                  animation
                  className={item.class || "unknown"}
                  show={this.state.showToast[item.idx]}
                  onClose={() => this.close(item.idx, item.pos)}
                  autohide
                  delay={5000}
                >
                  <Toast.Header>
                    <strong className={"mr-auto "}>
                      {item.header || "Unknown"}
                    </strong>
                    <small>{getTime()}</small>
                  </Toast.Header>
                  <Toast.Body>
                    {item.msg ||
                      "Some undocummented error occured. Please contact the administrator to look at this problem"}
                  </Toast.Body>
                </Toast>
              )
            );
          })}
          <Modal
            show={this.state.showModal}
            onHide={this.move}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Sukses!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.modalData.edit ? (
                <p>
                  Detail Nota No. <b>{this.state.modalData.no_nota}</b> telah
                  berhasil dirubah
                </p>
              ) : (
                <p>
                  Nota telah berhasil dibuat, dengan nomor nota yang terdaftar
                  diserver: <b>{this.state.modalData.no_nota}</b>
                </p>
              )}
              {this.state.modalData.changed && (
                <p>
                  Perhatian!! Nomor dari nota yang anda buat telah diubah
                  otomatis oleh server.
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.move}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="main">
          {this.state.mainLoading && this.state.fetchProgress >= 0 && (
            <ModalLoading
              usepbar={true}
              msg={this.state.fetchMessage}
              progression={this.state.fetchProgress / this.totalProgress}
            />
          )}
          {this.state.timeout && <Timeout {...this.props} />}
          {this.state.notaData && (
            <FormNotaCom
              generateToast={this.generateToast}
              showModal={this.showModal}
              {...this.props}
              data={this.state.notaData}
            />
          )}
        </div>
      </div>
    );
  }
}
FormNota.contextType = AuthContext;
export default FormNota;
