import Axios from "axios";

// const BASE_URL = "https://morning-bastion-23858.herokuapp.com/";
// const PERUSAHAAN_MASTER = "perusahaanMaster";
// const PERUSAHAAN_PENGIRIM = "perusahaanPengirim";
// const PRODUK = "produk";
// const ADMIN = "admin";
// const SUPIR = "supir";
// const KENDARAAN = "kendaraan";
// const PENERIMA = "costumer";
// const TITIK_PENGAMBILAN = "portal";
// const LOG = "log/"
// const NO_NOTA = "nota/noNota/"
// const NOTA = "nota"
// const NOTA_PREMI = "nota/premi"

const handleError = error => {
  let errorResponse = null;
  if (error.code === "ECONNABORTED") {
    errorResponse = {
      status: 408,
      error: [{ msg: error.message }],
      message: "Request Time Out"
    };
  } else if (error.response) {
    errorResponse = error.response.data;
  } else {
    //bisa karena gada error response, etc
    errorResponse = {
      status: 501,
      error: [{ msg: "Server Implementation error." }]
    };
  }
  return errorResponse;
};

export const fetchPerusahaan = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_PERUSAHAAN_MASTER,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchPerusahaanPengiriman = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_PERUSAHAAN_PENGIRIM,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchProduk = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_PRODUK,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchAdmin = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_ADMIN,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchSpecificAdmin = async (token, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_ADMIN + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchSupir = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_SUPIR,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchKendaraan = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_KENDARAAN,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchPenerima = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_PENERIMA,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchTitikPengambilan = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_TITIK_PENGAMBILAN,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchLog = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_LOG,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchNomorNota = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_NO_NOTA,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchNotaData = async (
  token,
  updateProgress,
  error,
  relogin,
  timeOut
) => {
  let data = {};
  let process = [
    {
      key: "no_nota",
      func: fetchNomorNota
    },
    {
      key: "perusahaan_pengirim",
      func: fetchPerusahaanPengiriman
    },
    {
      key: "portal",
      func: fetchTitikPengambilan
    },
    {
      key: "perusahaan_penerima",
      func: fetchPenerima
    },
    {
      key: "supir",
      func: fetchSupir
    },
    {
      key: "kendaraan",
      func: fetchKendaraan
    },
    {
      key: "produk",
      func: fetchProduk
    }
  ];
  let message = {
    0: "data perusahaan pengiriman",
    1: "data portal",
    2: "data customer",
    3: "data supir",
    4: "data kendaraan",
    5: "data produk"
  };

  for (let i = 0; i < process.length; i++) {
    let response = await process[i].func(token);
    if (response[0] && response[0].status === 200) {
      data = {
        ...data,
        [process[i].key]: response[0].data
      };
      token = response[0].token;
      updateProgress("Sedang mengambil " + message[i]);
    } else if (response[0] && response[0].status === 402) {
      relogin();
      return null;
    } else if (response[1] && response[1].status === 408) {
      timeOut();
      return null;
    } else {
      //throw ke server error
      error();
      return null;
    }
  }

  data = {
    ...data,
    token: token
  };

  data = sortNota(data);
  return data;
};

const sortNota = data => {
  if (data.perusahaan_penerima) {
    data.perusahaan_penerima.customers.sort(function(a, b) {
      if (a.nama_perusahaan < b.nama_perusahaan) {
        return -1;
      }
      if (a.nama_perusahaan > b.nama_perusahaan) {
        return 1;
      }
      return 0;
    });
  }
  if (data.perusahaan_pengirim) {
    data.perusahaan_pengirim.sort(function(a, b) {
      if (a.nama < b.nama) {
        return -1;
      }
      if (a.nama > b.nama) {
        return 1;
      }
      return 0;
    });
  }
  if (data.portal) {
    data.portal.sort(function(a, b) {
      if (a.nama < b.nama) {
        return -1;
      }
      if (a.nama > b.nama) {
        return 1;
      }
      return 0;
    });
  }
  if (data.produk) {
    data.produk.sort(function(a, b) {
      if (a.jenis < b.jenis) {
        return -1;
      }
      if (a.jenis > b.jenis) {
        return 1;
      }
      return 0;
    });
  }
  if (data.supir) {
    data.supir.supir.sort(function(a, b) {
      if (a.nama < b.nama) {
        return -1;
      }
      if (a.nama > b.nama) {
        return 1;
      }
      return 0;
    });
  }

  return data;
};

export const fetchPengiriman = async token => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_NOTA,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchSpecificPengiriman = async (token, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_NOTA + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};

export const fetchPremi = async (token, id) => {
  var responseData;
  var errorResponse = null;
  await Axios({
    method: "get",
    url: process.env.REACT_APP_NOTA_PREMI + "/" + id,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      responseData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [responseData, errorResponse];
};
