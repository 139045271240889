import React, { Component } from "react";
import SideBar from "../components/SideBar_Com";
import Table from "../components/Table_Com";
import "../styles/Table_Style.css";
import { fetchPengiriman } from "../logics/FetchData_Logic";
import { AuthContext } from "../context/Auth_Context";
import Timeout from "./Timeout_Page";
import ModalLoading from "../components/ModalLoading_Com";
import { Form, Col, Button, Modal, Spinner } from "react-bootstrap";
import { deleteNota } from "../logics/DeleteData_Logic";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import { formatNumber } from "../logics/FormatNumberPreview_Logic";
import { changeFormat } from "../logics/CreateDateTime_Logic";

var xlsx = require("json-as-xlsx");
const $ = require("jquery");
$.Datatable = require("datatables.net");

class TableRekapan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      data_raw: null,
      data_customer: null,
      timeout: false,
      mainLoading: true,
      filter_startDate: "",
      filter_endDate: "",
      filter_transportir: "",
      filter_customer: "",
      delData: null,
      loading: false,
      successDel: null,
      showModalDelete: false,
      customer_isEmpty: false,
      generatePdf: false
    };
    this.filterParameter = {
      startDate: "",
      endDate: "",
      customer: "",
      transportir: ""
    };
    this.customerFilterRef = React.createRef();
    this.startDateRef = React.createRef();
    this.endDateRef = React.createRef();
    this.transportirRef = React.createRef();
    // this.filter_startDate= "";
    // this.filter_endDate= "";
    // this.filter_transportir= "";
    // this.filter_jenisKendaraan= "";
  }

  activeTransportir(id_target, data) {
    for (let i = 0; i < data.length; i++) {
      if (id_target === data[i]._id) return data[i].nama;
    }
    return false;
  }

  activeCustomer(id_target, data) {
    for (let i = 0; i < data.length; i++) {
      if (id_target === data[i]._id) return data[i].nama_perusahaan;
    }
    return false;
  }
  async componentDidMount() {
    const { auth, setToken } = this.context;
    let obj = await fetchPengiriman(auth.token);
    let data_table = null;
    let timeout = false;
    let transportir_data = [];
    let customer_data = [];
    if (obj[0] !== undefined) {
      if (obj[0].status === 402) {
        let path = "/";
        this.props.history.push({
          pathname: path,
          state:
            "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
        });
      } else if (obj[0].status === 200) {
        let data = [];
        let data_temp = []; //data transportir
        let data_customer = [];
        for (let i = 0; i < obj[0].data.notaData.length; i++) {
          data_temp.push(obj[0].data.notaData[i].asal_pengiriman._id);
          data_customer.push(obj[0].data.notaData[i].tujuan_pengiriman._id);
          if (obj[0].data.notaData[i]["status"] !== 0) {
            data.push(obj[0].data.notaData[i]);
          }
        }
        data_temp = [...new Set(data_temp)];
        data_customer = [...new Set(data_customer)];
        for (let i = 0; i < data_temp.length; i++) {
          let res = this.activeTransportir(
            data_temp[i],
            obj[0].data.transportir
          );
          if (res) transportir_data.push(res);
        }
        for (let i = 0; i < data_customer.length; i++) {
          let res = this.activeCustomer(data_customer[i], obj[0].data.customer);
          if (res) customer_data.push(res);
        }
        transportir_data = transportir_data.sort();
        customer_data = customer_data.sort();
        data_table = {
          data: data,
          scrollY: 341,
          scrollCollapse: true,
          columns: [
            {
              data: "asal_pengiriman",
              render: function (data) {
                if (data === "~") return data;
                return data ? data.nama : "-";
              }
            },
            {
              data: null,
              defaultContent: "-",
              render: (data, type, row, meta) => {
                if (row.tujuan_pengiriman.nama_perusahaan === "$$$$$$$")
                  return row.nama_perusahaan_tujuan;
                return row.tujuan_pengiriman.nama_perusahaan;
              }
            },
            { data: "no_nota" },
            { data: "no_po" },
            { data: "no_polisi" },
            {
              data: "kendaraanId.jenis_kendaraan",
              render: function (data) {
                if (data === null) return "other";
                return data;
              },
              defaultContent: "other"
            },
            {
              data: "tanggal_pengirim",
              render: function (data, type, row, meta) {
                let date = new Date(data);
                if (type === "display" || type === "filter") {
                  if (data === "~") return data;
                  let year = date.getFullYear();
                  let month =
                    date.getMonth() < 9
                      ? "0" + (date.getMonth() + 1)
                      : date.getMonth() + 1;
                  let day =
                    date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                  return day + "-" + month + "-" + year;
                }
                if (data === "~") return 0;
                else return date.getTime() / 1000;
              }
            },
            {
              data: "status",
              render: function (data) {
                if (data === "~") return data;
                return data === undefined
                  ? "Error"
                  : data === 0
                  ? "Draft"
                  : data === 1
                  ? "Dalam Perjalanan"
                  : "Selesai";
              },
              orderable: false
            },
            {
              data: "status",
              orderable: false,
              className: "center",
              render: this.rowRender
              // render: function (data) {
              //     if (data === "~") return data;
              //     return data === undefined
              //         ? "Error"
              //         : (data === 0
              //             ? '<div class="icon_container"><img class="icon edit" src="assets/edit.svg"/></div>'
              //             : '<Button name="' + this.props.status + '" class="prev">Lihat</Button>'
              //           )
              // },
              //defaultContent: '<Button name="' + this.props.status + '" class="prev">Lihat</Button>'
            },
            {
              data: "empty",
              orderable: false,
              className: "center",
              defaultContent:
                '<div class="icon_container"><img class="icon delete" src="assets/trash.svg"/></div>'
            }
          ],
          order: [
            [5, "desc"],
            [2, "desc"]
          ],
          columnDefs: [
            {
              targets: [0, 1, 3],
              type: "text"
            },
            {
              targets: 2,
              type: "number"
            },
            {
              targets: 5,
              type: "dates"
            }
          ]
        };
        if (obj[0].token) setToken(obj[0].token);
      } else {
      }
    } else if (obj[1].status && obj[1].status === 408) {
      timeout = true;
    } else {
      this.props.history.push("/500ServerError");
    }
    this.setState({
      data: data_table,
      data_raw: transportir_data.length > 0 ? transportir_data.sort() : null,
      data_customer: customer_data.length > 0 ? customer_data : null,
      timeout: timeout,
      mainLoading: false
    });
    $.fn.dataTable.ext.search.push(
      //belum selesai
      (settings, data, dataIndex) => {
        let dateParts = data[5].split("-");
        let dateData = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        let transportir = data[0];
        let customer = data[1];

        let filter_startDate = $("#start_date").val();
        let filter_endDate = $("#end_date").val();
        let filter_transportir = $("#transportir").val();
        let filter_customer = $("#customer").val();

        dateData.setHours(0, 0, 0);
        if (filter_startDate) {
          let filterStartDate = new Date(filter_startDate);
          filterStartDate.setHours(0, 0, 0);
          if (dateData.getTime() < filterStartDate.getTime()) return false;
        }
        if (filter_endDate) {
          let filterEndDate = new Date(filter_endDate);
          filterEndDate.setHours(0, 0, 0);
          if (dateData.getTime() > filterEndDate.getTime()) return false;
        }
        if (filter_transportir && filter_transportir !== transportir)
          return false;
        if (filter_customer && filter_customer !== customer) return false;
        return true;
      }
    );
  }

  rowRender = (data) => {
    if (data === "~") return data;
    return data === undefined
      ? "Error"
      : data === 0
      ? '<div class="icon_container"><img class="icon edit" src="assets/edit.svg"/></div>'
      : '<Button name="Perusahaan" class="prev">Lihat</Button>';
  };

  applyFilter = () => {
    this.filterParameter["startDate"] = this.startDateRef.current.value;
    this.filterParameter["endDate"] = this.endDateRef.current.value;
    this.filterParameter["transportir"] = this.transportirRef.current.value;
    this.filterParameter["customer"] = this.customerFilterRef.current.value;
    this.setState({ customer_isEmpty: false }, () => {
      var table = $("#data_table").DataTable();
      table.draw();
    });
  };

  clearFilter = () => {
    this.setState(
      {
        filter_startDate: "",
        filter_endDate: "",
        filter_transportir: "",
        filter_customer: ""
      },
      () => {
        this.applyFilter();
      }
    );
  };

  compareDate = (a, b) => {
    if (a[7] < b[7]) return 1;
    if (a[7] > b[7]) return -1;
    return 0;
  };

  printFiltered = async () => {
    // if (this.customerFilterRef.current.value == "") {
    //   this.setState({ customer_isEmpty: true });
    //   return;
    // } else this.setState({ customer_isEmpty: false });
    var table = $("#data_table").DataTable();
    var print_data = [];
    var index_tabel = 1;
    var total_kuantitas = 0;
    var total_pemakaian = 0;
    table.rows({ search: "applied" }).every(function (index) {
      var row = table.row(index);
      var data = row.data();
      var tanggal = new Date(data.tanggal_pengirim);
      print_data.push([
        index_tabel, //index
        tanggal.getDate() +
          "/" +
          (tanggal.getMonth() + 1) +
          "/" +
          tanggal.getFullYear(),
        data.asal_pengiriman.nama, //transportir
        // data.tujuan_pengiriman.nama_perusahaan === "$$$$$$$"
        //   ? data.nama_perusahaan_tujuan || "-"
        //   : data.tujuan_pengiriman.nama_perusahaan, // tujuan
        data.site,
        data.no_polisi, // no.pol
        formatNumber(data.kuantitas) + "L", //kuantitas
        data.no_po, // no. po
        "Rp." + formatNumber(data.harga_produk),
        "Rp." + formatNumber(data.biaya_penyebrangan),
        "Rp." + formatNumber(data.biaya_premi),
        formatNumber(data.liter) + "L"
        // tanggal[2]+"/"+tanggal[1]+"/"+tanggal[0],
        // data.asal_pengiriman.nama,
        // data.tujuan_pengiriman.nama_perusahaan === "$$$$$$$"
        //   ? data.nama_perusahaan_tujuan || "-"
        //   : data.tujuan_pengiriman.nama_perusahaan,
        // data.site,
        // formatNumber(data.kuantitas),
        // data.liter || "-", //bisa ada bisa tidak
        // data.no_po,
        // data.kendaraanId.jenis_kendaraan || "-",
        // new Date(data.tanggal_pengirim),
      ]);
      total_kuantitas = total_kuantitas + data.kuantitas;
      if (typeof data.liter != "undefined") total_pemakaian += data.liter;
      index_tabel = index_tabel + 1;
    });
    total_kuantitas = formatNumber(total_kuantitas);
    total_pemakaian = formatNumber(total_pemakaian);
    //print_data.sort(this.compareDate)
    this.constructPdf(print_data, total_kuantitas, total_pemakaian);
  };

  downloadXLSX = async () => {
    var table = $("#data_table").DataTable();
    var print_data = [];
    var index_tabel = 1;
    var total_kuantitas = 0;
    var total_pemakaian = 0;
    table.rows({ search: "applied" }).every(function (index) {
      var row = table.row(index);
      var data = row.data();
      var tanggal = new Date(data.tanggal_pengirim);
      print_data.push({
        no: index_tabel, //index
        tanggal:
          tanggal.getDate() +
          "/" +
          (tanggal.getMonth() + 1) +
          "/" +
          tanggal.getFullYear(),
        transportir: data.asal_pengiriman.nama, //transportir
        tujuan: data.site,
        nopol: data.no_polisi, // no.pol
        kuantitas: formatNumber(data.kuantitas) + "L", //kuantitas
        nopo: data.no_po, // no. po
        harga: "Rp." + formatNumber(data.harga_produk),
        ongkos: "Rp." + formatNumber(data.biaya_penyebrangan),
        premi: "Rp." + formatNumber(data.biaya_premi),
        pemakaian: formatNumber(data.liter) + "L"
      });
      total_kuantitas = total_kuantitas + data.kuantitas;
      if (typeof data.liter != "undefined") total_pemakaian += data.liter;
      index_tabel = index_tabel + 1;
    });
    //console.log(print_data);
    var columns = [
      { label: "No", value: "no" },
      { label: "Tanggal", value: "tanggal" },
      { label: "Transportir", value: "transportir" },
      { label: "Tujuan", value: "tujuan" },
      { label: "No.Polisi", value: "nopol" },
      { label: "Kuantitas", value: "kuantitas" },
      { label: "No.PO", value: "nopo" },
      { label: "Harga", value: "harga" },
      { label: "Ongkos", value: "ongkos" },
      { label: "Premi", value: "premi" },
      { label: "Pemakaian", value: "pemakaian" }
    ];
    var settings = {
      sheetName: "FirstSheet", // The name of the sheet
      fileName: "MySpreadsheet", // The name of the spreadsheet
      extraLength: 3, // A bigger number means that columns should be wider
      writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    xlsx(columns, print_data, settings, true);
  };

  constructPdf = (data, total_kuantitas, total_pemakaian) => {
    (function (API) {
      //thx stackoverflow
      API.myText = function (txt, options, x, y) {
        options = options || {};
        if (options.align == "center") {
          var fontSize = this.internal.getFontSize();
          var pageWidth = this.internal.pageSize.width;
          var txtWidth =
            (this.getStringUnitWidth(txt) * fontSize) /
            this.internal.scaleFactor;
          x = (pageWidth - txtWidth) / 2;
        }
        this.text(txt, x, y);
      };
    })(jsPDF.API);

    var nota = new jsPDF("landscape", "pt", "a4");
    nota.setFontSize(18);
    nota.setFontStyle("bold");
    nota.myText(
      "Rekapan Pengiriman " + this.customerFilterRef.current.value,
      { align: "center" },
      0,
      40
    );
    nota.setFontSize(14);
    nota.setFontStyle("regular");
    nota.myText(
      "Tanggal: " +
        changeFormat(this.filterParameter.startDate) +
        " s.d. " +
        changeFormat(this.filterParameter.endDate) +
        ", Transportir: " +
        (this.filterParameter.transportir || "-"),
      { align: "center" },
      0,
      60
    );
    nota.setFontSize(10);
    nota.autoTable({
      head: [
        [
          "No",
          "Tanggal",
          "Transportir",
          "Tujuan",
          "No. Polisi",
          "Kuantitas",
          "No. PO",
          "Harga",
          "Ongkos",
          "Premi",
          "Pemakaian"
        ]
      ],
      body: data,
      startY: 80,
      theme: "grid",
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 1,
        fontSize: 7,
        minCellHeight: 20,
        valign: "middle"
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
        minCellHeight: 0
      },
      bodyStyles: {
        halign: "center",
        cellWidth: "wrap"
      },
      columnStyles: {
        0: { cellWidth: 35 },
        1: { cellWidth: 50 },
        2: { cellWidth: 125 }, //transportir
        3: { cellWidth: 88 }, //tujuan
        4: { cellWidth: 93 }, //no polisi
        5: { cellWidth: 70 }, //kuantitas
        6: { cellWidth: 100 }, //no po
        7: { cellWidth: 55 },
        8: { cellWidth: 50 },
        9: { cellWidth: 55 },
        10: { cellWidth: 40 }
      }
    });

    //page numbering for js pdf, thanks https://github.com/MrRio/jsPDF/issues/109
    const pages = nota.internal.getNumberOfPages();
    const pageWidth = nota.internal.pageSize.width; //Optional
    const pageHeight = nota.internal.pageSize.height; //Optional
    nota.setFontSize(10); //Optional

    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth / 2; //Can be fixed number
      let verticalPos = pageHeight - 15; //Can be fixed number
      nota.setPage(j);
      //nota.text(`${j} of ${pages}`, horizontalPos, verticalPos, {align: 'center'  //Optional text styling});
      nota.myText(
        `Halaman ${j} / ${pages}`,
        { align: "center" },
        horizontalPos,
        verticalPos
      );
    }

    nota.setPage(pages);
    nota.setFontSize(13);
    nota.setFontStyle("bold");
    nota.myText(
      "Total Kuantitas: " + total_kuantitas + " Liter",
      { align: "center" },
      0,
      nota.lastAutoTable.finalY + 30
    );
    nota.myText(
      "Total Pemakaian: " + total_pemakaian + " Liter",
      { align: "center" },
      0,
      nota.lastAutoTable.finalY + 50
    );
    nota.autoPrint();
    window.open(nota.output("bloburl"));
  };

  confirmation = (data) => {
    let temp = new Object();
    Object.assign(temp, data);
    console.log(temp);
    this.setState({
      delData: temp,
      showModalDelete: true
    });
  };

  delete = async () => {
    // belum jalan
    this.setState({
      loading: true
    });
    const { auth, setToken } = this.context;
    let token = auth.token;
    let success = false;
    let response = await deleteNota(
      token,
      this.state.delData._id,
      this.state.delData.no_nota
    );
    if (response[0] !== undefined) {
      if (response[0].status === 200) {
        success = true;
        if (response[0].token) setToken(response[0].token);
      } else if (response[0].status === 402) {
        let path = "/";
        this.props.history.push({
          pathname: path,
          state:
            "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
        });
      }
    }
    this.setState({
      loading: false,
      successDel: success
    });
  };

  handleClose = () => {
    if (this.state.successDel) {
      window.location.reload();
    } else {
      this.setState({
        showModalDelete: false
      });
    }
  };

  render() {
    return (
      <div className="home">
        {!this.state.timeout && <SideBar />}
        <div className="main">
          {this.state.mainLoading && <ModalLoading />}
          {this.state.timeout && <Timeout {...this.props} />}
          <div className="container-table">
            {this.state.data && (
              <>
                <Form className="form-filter">
                  <Form.Row id="rekap-filter-container">
                    <Form.Group as={Col}>
                      <Form.Label>Customer :</Form.Label>
                      <Form.Control
                        as="select"
                        name="customer"
                        id="customer"
                        isInvalid={this.state.customer_isEmpty}
                        ref={this.customerFilterRef}
                        value={this.state.filter_customer}
                        onChange={(e) =>
                          this.setState({
                            filter_customer: e.target.value,
                            customer_isEmpty: e.target.value ? false : true
                          })
                        }
                      >
                        <option value="">Pilih Customer</option>
                        {this.state.data_customer &&
                          this.state.data_customer.map((row) => {
                            return (
                              <option key={row} value={row}>
                                {row}
                              </option>
                            );
                          })}
                      </Form.Control>
                      {this.state.customer_isEmpty ? (
                        <div id="inv-cust-filter">
                          Pilihan customer tidak boleh kosong
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Tanggal Awal :</Form.Label>
                      <Form.Control
                        type="date"
                        name="start_date"
                        id="start_date"
                        value={this.state.filter_startDate}
                        ref={this.startDateRef}
                        onChange={(e) =>
                          this.setState({ filter_startDate: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Tanggal Akhir :</Form.Label>
                      <Form.Control
                        type="date"
                        name="end_date"
                        id="end_date"
                        value={this.state.filter_endDate}
                        ref={this.endDateRef}
                        onChange={(e) =>
                          this.setState({ filter_endDate: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Transportir :</Form.Label>
                      <Form.Control
                        as="select"
                        name="transportir"
                        id="transportir"
                        value={this.state.filter_transportir}
                        ref={this.transportirRef}
                        onChange={(e) =>
                          this.setState({ filter_transportir: e.target.value })
                        }
                      >
                        <option value="">Pilih Transportir</option>
                        {this.state.data_raw &&
                          this.state.data_raw.map((row) => {
                            return (
                              <option key={row} value={row}>
                                {row}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                    {/* <Form.Group as={Col} className="form-btn-filter">
                        <Form.Label></Form.Label>
                        <Button onClick={this.applyFilter} variant="primary">
                          Apply Filter
                        </Button>
                    </Form.Group>
                    <Form.Group as={Col} className="form-btn-filter">
                        <Form.Label> </Form.Label>
                        <Button onClick={this.applyFilter} variant="secondary">
                          Clear Filter
                        </Button>
                    </Form.Group>
                    <Form.Group as={Col} className="form-btn-filter">
                        <Form.Label> </Form.Label>
                        <Button onClick={this.applyFilter} variant="primary">
                          Print Report
                        </Button>
                    </Form.Group> */}
                  </Form.Row>
                  <Form.Row className="justify-content-md-center">
                    <Col md="3" lg="3">
                      <Button
                        onClick={() => {
                          if (this.customerFilterRef.current.value != "") {
                            this.applyFilter();
                          } else this.setState({ customer_isEmpty: true });
                        }}
                        variant="primary"
                      >
                        Apply Filter
                      </Button>
                    </Col>
                    <Col md="3" lg="3">
                      <Button onClick={this.clearFilter} variant="secondary">
                        Clear Filter
                      </Button>
                    </Col>
                    <Col md="3" lg="3">
                      <Button
                        onClick={this.printFiltered}
                        disabled={this.state.generatePdf}
                        variant="primary"
                      >
                        Print Report
                      </Button>
                    </Col>
                    <Col md="3" lg="3">
                      <Button
                        onClick={this.downloadXLSX}
                        //disabled={this.state.generatePdf}
                        variant="primary"
                      >
                        Download
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
                <Table
                  {...this.props}
                  status={"TableNota"}
                  data={this.state.data}
                  delete={this.confirmation}
                />
                <Modal
                  backdrop="static"
                  show={this.state.showModalDelete}
                  onHide={this.handleClose}
                >
                  <Modal.Header closeButton={!this.state.loading}>
                    <Modal.Title>Konfirmasi Hapus</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {this.state.successDel === null && this.state.delData ? (
                      <>
                        <p>Apakah anda yakin untuk menghapus data berikut:</p>
                        <ul>
                          <li>Nomor: {this.state.delData.no_nota}</li>
                          <li>
                            Tranpsortir:
                            {this.state.delData.asal_pengiriman.nama}
                          </li>
                          <li>
                            Penerima:{" "}
                            {this.state.delData.nama_perusahaan_tujuan || "-"}
                          </li>
                          <li>No. PO: {this.state.delData.no_po}</li>
                          <li>
                            Tanggal:{" "}
                            {this.state.delData.tanggal_pengirim.split("T")[0]}
                          </li>
                          <li>
                            Status:{" "}
                            {this.state.delData.status === 0
                              ? "Draft"
                              : this.state.delData.status === 1
                              ? "Dalam Perjalanan"
                              : "Selesai"}
                          </li>
                        </ul>
                      </>
                    ) : this.state.successDel ? (
                      <p>Rekord berhasil dihapus</p>
                    ) : (
                      <p>
                        Error, terjadi kesalahan saat ingin menghapus rekord
                      </p>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    {this.state.successDel !== null ? (
                      <Button variant="secondary" onClick={this.handleClose}>
                        Close
                      </Button>
                    ) : this.state.loading ? (
                      <Button variant="danger" disabled>
                        <Spinner animation="border" variant="secondary" />
                      </Button>
                    ) : (
                      <>
                        <Button variant="secondary" onClick={this.handleClose}>
                          Close
                        </Button>
                        <Button variant="danger" onClick={this.delete}>
                          Delete
                        </Button>
                      </>
                    )}
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

TableRekapan.contextType = AuthContext;
export default TableRekapan;
