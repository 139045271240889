import { Button, Form, Col, Row, Image, Spinner } from "react-bootstrap";
import React, { Component, useContext, useState, useEffect } from "react";
import Kota from "../data/Data"
import AutoComplete from "./AutoComplete_Com"
import { useForm, ErrorMessage } from "react-hook-form"
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
import { handleTextInput, handleNumberInput, condensingWhitespace } from "../logics/FormInput_Logic";
import { useAuth } from '../context/Auth_Context';
import { submitPerusahaanPengirim, editPerusahaanPengirim } from '../logics/SubmitForm_Logic';

function FormPerusahaanPengirimanFunCom(props) {
    const { register, handleSubmit, errors, setValue, getValues } = useForm({
        mode: "onChange",
        defaultValues: {
            nama: props.location.state ? props.location.state.nama : "",
            penanggungjawab: props.location.state ? props.location.state.penanggung_jawab : "",
            no_hp: props.location.state ? props.location.state.no_hp : "",
            alamat: props.location.state ? props.location.state.alamat : "",
            kota: props.location.state ? props.location.state.kota : "",
            kode_pos: props.location.state ? props.location.state.kode_pos : "",
            id: props.location.state ? props.location.state._id : "",
            state: props.location.state ? "edit" : "input"
        }
    });
    const { auth,setToken } = useAuth();
    const [loading, setLoading] = useState(false);


    const onSubmit = async value => {
        setLoading(true)
        let token = auth.token;
        let success = false;
        let status = [];
        let message = [];
        value = condensingWhitespace(value)
        let response = getValues("state") === "edit" ? await editPerusahaanPengirim(value, token, getValues("id")) : await submitPerusahaanPengirim(value, token);;
        if (response[0] && response[0].status === 200) {
            response[0].token && setToken(response[0].token)
            success = true;
        } else if (response[0] && response[0].status === 402) {
            let path = "/";
            this.props.history.push({
                pathname: path,
                state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
            })
        } else {
            if (response[1]) {
                response[1].error.map((item) => {
                    status = status.concat(1);
                    message = message.concat("Status: " + response[1].status + " - " + response[1].message + ". " + item.msg);
                })
            }
            else {
                status = status.concat(1);
                message = message.concat("Status: " + response[0].status + " - " + response[0].message + ". " + response[0].error[0].msg);
            }
        }
        setLoading(false)
        if(success) {
            props.showModal(getValues("state"),value.nama);
        } else {
            let idx = 0;
            status.map(function(item){
                props.generateToast(item,message[idx]);
                idx = idx + 1;
            })
        }
    }

    const handleChange = (event) => {
        let val = "";
        if (event.target.name === "no_hp" || event.target.name === "kode_pos") {
            val = handleNumberInput(event);
        } else {
            val = handleTextInput(event.target.name === "nama" ? "perusahaan" : event.target.name, event);
        }
        setValue(event.target.name, val);
    }

    const childSetValue = (name, value) => {
        setValue(name, value);
    }

    return (
        <div className="container">
            {getValues("state") === "edit" && <h4>Sedang mengedit transportir: {props.location.state.nama}</h4>}
            <Form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formBasicNamaPerusahaanPengirim">
                    <Form.Label >Nama Transportir :</Form.Label>
                    <Form.Control
                        className={errors.nama && "invalid"}
                        type="text"
                        name="nama"
                        //value={getValues("nama")}
                        ref={register({ required: "Input tidak boleh kosong" })}
                        onChange={handleChange} />
                    <ErrorMessage errors={errors} name="nama">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} controlId="formBasicNamaPenanggunJawab">
                        <Form.Label>Nama Penanggung Jawab  :</Form.Label>
                        <Form.Control
                            className={errors.penanggungjawab && "invalid"}
                            type="text"
                            name="penanggungjawab"
                            //value={getValues("penanggungjawab")}
                            ref={register({ required: "Input tidak boleh kosong" })}
                            onChange={handleChange} />
                        <ErrorMessage errors={errors} name="penanggungjawab">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBasicHP">
                        <Form.Label for="phone" >No Hp :</Form.Label>
                        <Form.Control
                            className={errors.no_hp && "invalid"}
                            type="tel"
                            name="no_hp"
                            //value={getValues("no_hp")}
                            ref={register({
                                required: "Input tidak boleh kosong",
                                pattern: { value: /^[+-]?\d+$/, message: "Format salah, contoh yang benar: 02112312" },
                                maxLength: { value: 16, message: "Nomor terlalu panjang, maksimal 16 digit" },
                                minLength: { value: 8, message: "Nomor terlalu pendek, minimal 8 digit" }
                            })}
                            onChange={handleChange} />
                        <ErrorMessage errors={errors} name="no_hp">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                </Form.Row>
                <Form.Group controlId="formBasicAlamat">
                    <Form.Label >Alamat Lengkap  :</Form.Label>
                    <Form.Control
                        className={errors.alamat && "invalid"}
                        type="text"
                        name="alamat"
                        //value={getValues("alamat")}
                        ref={register({ required: "Input tidak boleh kosong" })}
                        onChange={handleChange} />
                    <ErrorMessage errors={errors} name="alamat">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} controlId="formBasicKota">
                        <Form.Label >Kota :</Form.Label>
                        {/* <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            onChange={setSingleSelections}
                            options={Kota}
                            selected={singleSelections}
                            reference={register({ required: "Input tidak boleh kosong" })}
                            setValue={childSetValue}
                            errors={errors}
                        /> */}
                        <AutoComplete
                            suggestions={Kota}
                            reference={register({ required: "Input tidak boleh kosong" })}
                            setValue={childSetValue}
                            errors={errors}
                        />
                        <ErrorMessage errors={errors} name="kota">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBasicKodePos">
                        <Form.Label >Kode Pos  :</Form.Label>
                        <Form.Control
                            className={errors.kode_pos && "invalid"}
                            type="text"
                            name="kode_pos"
                            //value={getValues("kode_pos")}
                            ref={register({
                                required: "Input tidak boleh kosong",
                                maxLength: {
                                    value: 5,
                                    message: "Input tidak boleh kurang / lebih dari 5 digit"
                                },
                                minLength: {
                                    value: 5,
                                    message: "Input tidak boleh kurang / lebih dari 5 digit"
                                }
                            })}
                            onChange={handleChange}
                        />
                        <ErrorMessage errors={errors} name="kode_pos">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                </Form.Row>
                <div className="btn-container">
                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? <Spinner animation="border" variant="secondary"/> : "Save" }
                    </Button>
                </div>
            </Form>
        </div>
    )
}

class FormPerusahaanPengirimanCom extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <FormPerusahaanPengirimanFunCom {...this.props} />
    }
}

export default FormPerusahaanPengirimanCom;
