/*Line 86-96*/
import { Button, Form, Image, Col, Spinner, InputGroup } from "react-bootstrap";
import React, { Component, useState } from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
import { upload, submitKendaraan, editKendaraan } from "../logics/SubmitForm_Logic"
import { useAuth } from "../context/Auth_Context"
import { handleTextInput, handleNumberInput, condensingWhitespace } from "../logics/FormInput_Logic";
import { deleteGambar } from "../logics/DeleteData_Logic";
import { getLocalDate } from "../logics/CreateDateTime_Logic";
import { fetchPerusahaanPengiriman } from "../logics/FetchData_Logic";

function FormKendaraanFunCom(props) {
    const { register, handleSubmit, errors, setValue, getValues } = useForm({
        mode: "onChange",
        defaultValues: {
            jenis: props.edit ? props.edit.jenis : "",
            kapasitas: props.edit ? props.edit.kapasitas : "",
            no_polisi: props.edit ? props.edit.no_polisi : "",
            //jenis_bahan_bakar: props.edit ? props.edit.jenis_bahan_bakar : "",
            jenis_kendaraan: props.edit ? props.edit.jenis_kendaraan : "",
            tgl_exp: props.edit ? (props.edit.tgl_exp.split("T"))[0] : "",
            perusahaan_pemilik: props.edit ? props.edit.perusahaan_pemilik : "", //tunggu update pakai data peruashaan pengirim
            path_uri_expire: props.edit ? props.edit.path_uri_expire : "",
            id: props.edit ? props.edit._id : "",
            perusahaan_pemilik: props.edit ? props.edit.idPengirim._id : "",
            state: props.edit ? "edit" : "input"
        }
    });

    const [state, setState] = useState({
        image: props.edit ? props.edit.path_uri_expire : "assets/image.png",
        formData: null,
        loading: false,
        imgPath: null
    })
    
    const [perusahaan_pemilik,setPemilik] = useState({
        data: null,
        loading: false,
    })

    const { auth, setToken } = useAuth();
    const max = 2097152

    const inputImage = (event) => {
        let obj = new FormData();
        obj.append("image", event.target.files[0])
        setState({
            ...state,
            image: URL.createObjectURL(event.target.files[0]),
            formData: obj,
            imgPath: event.target.files[0].name
        })
    }

    const handleChange = (event) => {
        let val = "";
        if (event.target.name === "kapasitas") {
            val = handleNumberInput(event)
            val = val ? parseInt(val, 10) + "" : ""
        }
        else val = handleTextInput(event.target.name, event);
        setValue(event.target.name, val);
    }

    // const handleClick = async(event) =>{
    //     if(!perusahaan_pemilik.data){
    //         setPemilik({
    //             ...perusahaan_pemilik,
    //             loading:true
    //         })
    //         let resp = await fetchPerusahaanPengiriman(auth.token)
    //         if(resp[0] && resp[0].status === 200){
    //             setPemilik({
    //                 data: resp[0].data,
    //                 loading: false
    //             })
    //             setToken(resp[0].token)
    //         } else {
    //             props.history.push("/500ServerError")
    //         }
    //     }
    // }

    const validateImage = (value) => {
        return value[0] ? value[0].size <= max : getValues("state") === "edit";;
    }

    const onSubmit = async value => {
        setState({
            ...state,
            loading: true
        })
        value.kapasitas = parseInt(value.kapasitas, 10)
        value.idPerusahaan = auth.idPerusahaan;
        let success = false;
        let didUpload = false;
        let tahap_1 = true;
        let status = [];
        let message = [];
        value = condensingWhitespace(value)
        if (value.imgPhoto.length === 0) value.path_uri_expire = getValues("path_uri_expire");
        else {
            let response_image = await upload(auth.token, state.formData);
            if (response_image[0] && response_image[0].status === 200) {
                didUpload = true;
                value.path_uri_expire = response_image[0].data[0].url;
                response_image[0].token && setToken(response_image[0].token)
            }
            else if (response_image[0] && response_image[0].status === 402) {
                let path = "/";
                props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            }
            else {
                if (response_image[1]) {
                    response_image[1].error.map((item) => {
                        status = status.concat(1);
                        message = message.concat("Status: " + response_image[1].status + " - " + response_image[1].message + ". " + item.msg);
                    })
                } else {
                    status = status.concat(1);
                    message = message.concat("Status: " + response_image[0].status + " - " + response_image[0].message + ". " + response_image[0].error[0].msg);
                }
                tahap_1 = false;
            }
        }

        let response = null;
        if (tahap_1) {
            response = getValues("state") === "edit" ? await editKendaraan(value, auth.token, getValues("id")) : await submitKendaraan(value, auth.token);
            if (response[0] && response[0].status === 200) {
                response[0].token && setToken(response[0].token)
                success = true;
            } else if (response[0] && response[0].status === 402) {
                let path = "/";
                this.props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            } else {
                if (response[1]) {
                    response[1].error.map((item) => {
                        status = status.concat(1);
                        message = message.concat("Status: " + response[1].status + " - " + response[1].message + ". " + item.msg);
                    })
                }
                else {
                    status = status.concat(1);
                    message = message.concat("Status: " + response[0].status + " - " + response[0].message + ". " + response[0].error[0].msg);
                }
                let responseDel = didUpload ? await deleteGambar(auth.token, value.path_uri_expire) : null;
                if (responseDel !== null) {
                    if (responseDel[0] && responseDel[0].status === 402) {
                        let path = "/";
                        props.history.push({
                            pathname: path,
                            state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                        })
                    } else if (responseDel[0] && responseDel[0].status === 200) {
                        responseDel[0].token && setToken(responseDel[0].token)
                    } else {
                        if (responseDel[1]) {
                            responseDel[1].error.map((item) => {
                                status = status.concat(1);
                                message = message.concat("Status: " + responseDel[1].status + " - " + responseDel[1].message + ". " + item.msg);
                            })
                        } else {
                            status = status.concat(1);
                            message = message.concat("Status: " + responseDel[0].status + " - " + responseDel[0].message + ". " + responseDel[0].error[0].msg);
                        }
                    }
                }
            }
        }
        setState({
            ...state,
            loading: false
        });
        if (success) {
            getValues("state") === "input" ?
                props.showModal(getValues("state")) :
                props.showModal(getValues("state"));
        } else {
            let idx = 0;
            status.map(function (item) {
                props.generateToast(item, message[idx]);
                idx = idx + 1;
            })
        }
    }

    return (
        <div className="container">
            {getValues("state") === "edit" && <h4>Sedang mengedit kendaraan dengan nomor polisi: {props.edit.no_polisi}</h4>}
            <Form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formBasicKendaraan">
                    <Form.Label >Nama Armada :</Form.Label>
                    <Form.Control
                        className={errors.jenis && "invalid"}
                        type="text"
                        placeholder="Mobil Kapal"
                        name="jenis"
                        onChange={handleChange}
                        ref={register({ required: "Input tidak boleh kosong" })}
                    />
                    <ErrorMessage errors={errors} name="jenis">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                <Form.Row>
                    <Form.Group as={Col} controlId="formBasicKapasitas">
                        <Form.Label >Kapasitas Armada :</Form.Label>
                        <InputGroup className="mb-3">   
                            <Form.Control
                                className={errors.kapasitas && "invalid"}
                                //as="select"
                                type="text"
                                name="kapasitas"
                                onChange={handleChange}
                                placeholder={"Satuan Liter"}
                                ref={register({ required: "Input tidak boleh kosong" })}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>Liter</InputGroup.Text>
                            </InputGroup.Append>
                            {/* <option value="">Pilih Kapasitas</option>
                                <option value={5}>5KL</option>
                                <option value={10}>10KL</option>
                                <option value={20}>20KL</option>
                            </Form.Control> */}
                            <ErrorMessage errors={errors} name="kapasitas">
                                {({ message }) => <p className="error_info">{message}</p>}
                            </ErrorMessage>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBasicNoKendaraan">
                        <Form.Label >No Armada :</Form.Label>
                        <Form.Control
                            type="text"
                            name="no_polisi"
                            className={errors.no_polisi && "invalid"}
                            onChange={handleChange}
                            ref={register({ required: "Input tidak boleh kosong" })}
                        />
                        <ErrorMessage errors={errors} name="no_polisi">
                            {({ message }) => <p className="error_info">{message}</p>}
                        </ErrorMessage>
                    </Form.Group>
                </Form.Row>
                {/* <Form.Group controlId="formBasicJenisBahanBakar">
                    <Form.Label >Jenis Bahan Bakar Kendaraan :</Form.Label>
                    <Form.Control
                        type="text"
                        name="jenis_bahan_bakar"
                        className={errors.jenis_bahan_bakar && "invalid"}
                        onChange={handleChange}
                        ref={register({ required: "Input tidak boleh kosong" })}
                    />
                    <ErrorMessage errors={errors} name="jenis_bahan_bakar">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group> */}
                <Form.Group controlId="formBasicJenisBahanBakar">
                    <Form.Label >Jenis Kendaraan :</Form.Label>
                    <Form.Control
                        as="select"
                        name="jenis_kendaraan"
                        className={errors.jenis_kendaraan && "invalid"}
                        ref={register({ required: "Input tidak boleh kosong" })}
                    >
                        <option key={0} value="">Pilih Jenis Kendaraan</option>
                        <option key={1} value="mobil">Mobil</option>
                        <option key={2} value="kapal">Kapal</option>
                    </Form.Control>
                    <ErrorMessage errors={errors} name="jenis_kendaraan">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                {/*Bagian ini dibuat auto reminder kalo misalkan jatuh tempotnya tinggal 2 minggu lagi*/}
                <Form.Group controlId="formBasicExpired">
                    <Form.Label >Tanggal Expired Surat Ijin Armada :</Form.Label>
                    <Form.Control
                        type="date"
                        name="tgl_exp"
                        min={"2010-01-01"}
                        className={errors.tgl_exp && "invalid"}
                        defaultValue={getValues("tgl_exp")}
                        placeholder="Pilih Tanggal"
                        ref={register({ required: "Input tidak boleh kosong" })} />
                    <ErrorMessage errors={errors} name="tgl_exp">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                {/* <Form.Group controlId="formBasicEmail">
                    <Form.Label >Nama Perusahaan Milik Kendaraan :</Form.Label>
                    <Form.Control
                        className={errors.perusahaan_pemilik && "invalid"}
                        type="text"
                        name="perusahaan_pemilik"
                        onChange={handleChange}
                        ref={register({ required: "Input tidak boleh kosong" })} />
                    <ErrorMessage errors={errors} name="perusahaan_pemilik">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group> */}
                 <Form.Group>
                    <Form.Label >Nama Perusahaan Milik Armada : {perusahaan_pemilik.loading && <Spinner variant="primary" animation="border" />}</Form.Label>
                    <Form.Control
                        className={errors.perusahaan_pemilik && "invalid"}
                        as="select"
                        name="perusahaan_pemilik"
                        //disabled={perusahaan_pemilik.loading}
                        //onClick={handleClick}
                        ref={register({ required: "Input tidak boleh kosong" })}
                    >
                    <option value="">Pilih Perusahaan Pemilik</option>
                        {
                            props.data && props.data.map((item,key) => 
                                <option name="supir" value={item._id} key={item._id}>{item.nama}</option>)
                        }
                    </Form.Control>
                    <ErrorMessage errors={errors} name="perusahaan_pemilik">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>
                </Form.Group>
                <div className="container-image">
                    <div className="form-image">
                        <div className="input-group">
                            <div className="custom-file">
                                <input
                                    accept="image/*"
                                    type="file"
                                    className="custom-file-input"
                                    name="imgPhoto"
                                    onChange={inputImage}
                                    ref={getValues("state") !== "edit" ? register({
                                        required: true,
                                        validate: value => validateImage(value) || 'Gambar yang anda masukkan melebihi batas maksimal (2MB)'
                                    }) : register({
                                        validate: value => validateImage(value) || 'Gambar yang anda masukkan melebihi batas maksimal (2MB)'
                                    })}
                                />
                                <label
                                    className={errors.imgPhoto ? "custom-file-label invalid" : "custom-file-label"}
                                    htmlFor="inputGroupFile01"
                                >
                                    {state.imgPath ? state.imgPath : "Surat Izin Kendaraan"}
                                </label>
                            </div>
                        </div>
                        <div className="btn-image">
                            <Image
                                src={state.image}
                                style={{
                                    width: "13rem",
                                    height: "13rem",
                                    marginBottom: 15,
                                    alignSelf: "center"
                                }}
                            />
                        </div>
                    </div>
                </div>
                <ErrorMessage errors={errors} name="imgPhoto">
                    {({ message }) => <p className="error_info">{message}</p>}
                </ErrorMessage>
                <div className="btn-container">
                    <Button variant="primary" type="submit" disabled={state.loading}>
                        {state.loading ? <Spinner animation="border" variant="secondary" /> : "Save"}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

class FormKendaraanCom extends Component {
    constructor(props) {
        super(props);
    }


    render() { return <FormKendaraanFunCom {...this.props} /> }
}

export default FormKendaraanCom;
