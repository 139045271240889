export function handleTextInput(name, event) {
  let res = event.target.value;
  if (res === " ") return ""
  let regex_text = /^[a-zA-Z\s]+$/;
  let regex_email = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
  let regex_lc = /(\b[a-z](?!\s))/g;
  let status = false;

  if(name==="nama" || name==="penerima" || name==="penanggungjawab") name = "name"
  switch (name) {
    case "email":
      res = res.replace(/\s/g, "");
      status = event.target.value.match(regex_email) ? true : false;
      break;
    case "name":
      res = res.replace(regex_lc, function (x) {
        return x.toUpperCase();
      });
      res = res.replace(/\s\s+/g, " ");
      res = res.replace(/[^a-zA-Z0-9\s]/g, "");
      status = event.target.value.match(regex_text) ? true : false;
      break;
    case "kota":
      res = res.replace(regex_lc, function (x) {
        return x.toUpperCase();
      });
      res = res.replace(/\s\s+/g, " ");
      status = event.target.value.match(regex_text) ? true : false;
      break;
    case "username":
      res = res.replace(/\s/g, "");
      break;
    case "no_polisi":
      res = res.replace(/[^a-zA-Z0-9\s]/g, "");
      res = res.replace(/\s\s+/g, " ");
      break;
    case "perusahaan_pemilik":
      res = res.replace(/\s\s+/g, " ");
      status = true;
      break;
    case "perusahaan":
      res = res.replace(/\s\s+/g, " ");
      status = true;
      break;
    case "no_po":
      res = res.replace(/\s\s+/g, " ");
      status = true;
      break;
    default:
      res = res.replace(regex_lc, function (x) {
        return x.toUpperCase();
      });
      res = res.replace(/\s\s+/g, " ");
      status = true;
      break;
  }
  return res;
};

export function handleNumberInput(event) {
  let res = event.target.value;
  let regex_number = /[^\d]/g;

  res = res.replace(regex_number, "");

  return res;
}

export function condensingWhitespace(data) {
  const condenseWhitespace = require('condense-whitespace');

  const keys = Object.keys(data)
  for (const key of keys) {
    if (typeof data[key] === 'string') {
      data[key] = condenseWhitespace(data[key])
    }
  }

  return data;
}