import React, { Component } from "react";
import FormPerusahaanPengirimanCom from "../components/FormPerusahaanPengiriman_Com";
import SideBar from "../components/SideBar_Com";
import { Modal, Toast, Button } from "react-bootstrap";
import { getTime } from "../logics/CreateDateTime_Logic";

class FormPerusahaanPengiriman extends Component {
    constructor(props){
        super(props);
        this.state={
            showToast: [],
            toast_data: [],
            showModal:false,
            modalData: {}
        }
    }

    close(index,pos){//destroy
        let show_arr = [...this.state.showToast];
        show_arr[index] = false;
        this.setState({
            showToast: show_arr
        },this.destroy(pos));
    }

    destroy(position){//nge rerender ketika masih ada yg di show apakah ga bakal masalah?
        let toast_data = [...this.state.toast_data];
        toast_data.splice(position,1,null);
        this.setState({
            toast_data : toast_data
        })
    }

    move = () => {
        this.props.history.push("/TablePerusahaanPengiriman");
    }

    showModal = (type,data) => {
        this.setState({
            showModal:true,
            modalData: {
                data: data,
                type: type
            }
        })
    }

    generateToast = (status,message) => {
        let idx = this.state.showToast.length
        let header = "";
        let header_class = ""
        switch(status){
            case 0: //success
                header_class="success"
                header="Success!!"
                break;
            case 1: //error from input
                header_class="warning"
                header="Error!!"
                break;
            case 2: //error from server
                header_class="danger"
                header="Error!!"
                break;
            default:
                header_class=""
                header="Unknown"
                break;
        }
        let data = {
            class: header_class,
            header: header,
            msg: message,
            idx: idx,
            pos: this.state.toast_data.length
        }
        this.setState({
            toast_data: this.state.toast_data.concat(data),
            showToast: this.state.showToast.concat(true)
        })
    }

    render() {
        return (
            <div className="home">
                <SideBar/>
                <div className="toast_container">
                {
                    this.state.toast_data.map((item) => {
                        return item !== null && (
                        <Toast animation className={item.class || "unknown"} show={this.state.showToast[item.idx]} onClose={() => this.close(item.idx,item.pos)} autohide delay={5000}>
                            <Toast.Header>
                                <strong className={"mr-auto "}>{item.header || "Unknown"}</strong>
                                <small>{getTime()}</small>
                            </Toast.Header>
                            <Toast.Body>{item.msg || "Some undocummented error occured. Please contact the administrator to look at this problem"}</Toast.Body>
                        </Toast>)
                    })
                }
                <Modal show={this.state.showModal} onHide={this.move} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Sukses!!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Data transportir <b>{this.state.modalData.data}</b> berhasil {this.state.modalData.type === "edit" ? "diubah" : "disimpan"}.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.move}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                </div>
                <div className="main">
                    <FormPerusahaanPengirimanCom generateToast={this.generateToast} showModal={this.showModal}  {...this.props}/>
                </div>
            </div>
        );
    }
}

export default FormPerusahaanPengiriman;