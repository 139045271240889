import { Button, Form, Spinner} from "react-bootstrap";
import {useForm, ErrorMessage} from "react-hook-form";
import React, { Component } from "react";
import {useAuth} from "../context/Auth_Context";
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
import {submitProduk} from "../logics/SubmitForm_Logic"
import {handleTextInput, condensingWhitespace} from "../logics/FormInput_Logic"
import { useState } from "react";

function FormProdukFunCom(props){
    const {register, handleSubmit, errors, setValue} = useForm({mode:"onChange"});
    
    const { auth, setToken } = useAuth();
    const [loading,setLoading] = useState(false);


    const onSubmit = async value => {
        setLoading(true)
        let token = auth.token;
        let success = false;
        let status = [];
        let message = [];
        value = condensingWhitespace(value);
        let response = await submitProduk(value,token);
        if(response[0]  && response[0].status === 200){
            response[0].token && setToken(response[0].token)
            success = true;
        } else if (response[0] && response[0].status === 402) {
            let path = "/";
            this.props.history.push({
                pathname: path,
                state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
            })
        } else{
            if(response[1]) {
                response[1].error.map((item)=>{
                    status = status.concat(1);
                    message = message.concat("Status: "+response[1].status+" - "+response[1].message + ". " + item.msg);
                })
            }
            else {
                status = status.concat(1);
                message = message.concat("Status: "+response[0].status+" - "+response[0].message + ". " + response[0].error[0].msg);
            }
        }

        setLoading(false)
        if(success) {
            props.showModal(value.jenis);
        } else {
            let idx = 0;
            status.map(function(item){
                props.generateToast(item,message[idx]);
                idx = idx + 1;
            })
        }
    }

    const handleChange = (event) => {
        let val = handleTextInput(event.target.name,event); 
        setValue(event.target.name,val);
    }
    return(
        <div className="container">
            <Form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label >Jenis Produk :</Form.Label>
                    <Form.Control 
                        className={errors.jenis && "invalid"}
                        type="text"
                        name="jenis"
                        onChange={handleChange}
                        ref={register({required: "Input tidak boleh kosong"})}
                        />  
                    <ErrorMessage errors={errors} name="jenis">
                        {({ message }) => <p className="error_info">{message}</p>}
                    </ErrorMessage>                              
                </Form.Group>                
                <div className="btn-container">
                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading ? <Spinner animation="border" variant="secondary"/> : "Save" }
                    </Button>
                </div>
            </Form>
        </div>
    )
}

class FormProdukCom extends Component{
    constructor(props){
        super(props);
    }
    render(){return <FormProdukFunCom {...this.props}/>}
}

export default FormProdukCom;
