import { Button, Form, Image, Spinner, Toast, ProgressBar, InputGroup } from "react-bootstrap";
import React, { Component } from "react";
// import ModalLoading from "../components/ModalLoading_Com"
import '../styles/LoginForm_Style.css'
import '../styles/Form_Style.css'
import '../styles/Colour_Style.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { hitLogin } from "../logics/SubmitForm_Logic";
import { AuthContext, LevelContext } from "../context/Auth_Context";
// import {
//     fetchPerusahaanPengiriman,
//     fetchTitikPengambilan,
//     fetchPenerima,
//     fetchSupir,
//     fetchKendaraan,
//     fetchProduk,
//     fetchNotaData
// } from "../logics/FetchData_Logic";

class LoginForm extends Component {
    constructor(props) {
        super(props);

        let token = localStorage.getItem("tokens")
        let level = localStorage.getItem("level")
        if (!this.props.location.state && token && level) {
            switch (level) {
                case "1":
                    this.props.history.push("/FormNota");
                    break;
                case "2":
                    this.props.history.push("/TableSupir");
                    break;
                case "3":
                    this.props.history.push("/TablePerusahaan");
                    break;
                default:
                    this.props.history.push("/UnknownLevelAccess");
                    break;
            }
        }

        this.state = {
            username: "",
            password: "",
            time: this.getCurrentTime(),
            show: this.props.location.state ? true : false,
            toastMsg: this.props.location.state || "",
            setAuthData: null,
            loading: false,
            success: false,
            progress: 0,
            fetchStatus: false,
            errorFetch: false,
            errorFetchMessage: null,
            hidden: true,
            addClass: false,
            modalShow: false,
            setToken: null
        }
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.toggleShowPassword = this.toggleShowPassword.bind(this);
        this.newToken = ""
    }
    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    toggleShowPassword() {
        this.setState({ hidden: !this.state.hidden });
    }
    componentDidMount() {
        const { setAuth, setToken } = this.context;

        if(this.props.location.state){
            localStorage.clear();
            setAuth({token:'',level:'',idPerusahaan:''})
        }
        if (this.props.password) {
            this.setState({ password: this.props.password });
        }
        this.setState({
            setAuthData: setAuth,
            setToken: setToken
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    getCurrentTime = () => {
        let val = new Date().toLocaleString();
        return val.substring(0, val.length - 3);
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    updateProgress = response => {
        this.newToken = response
        this.setState({
            progress: this.state.progress + 1
        })
    }

    setError = (response) => {
        localStorage.clear();
        this.setState({
            errorFetch: true,
            errorFetchMessage: response,
            fetchStatus: false,
        })
    }

    redirect = async (data) => {
        //await new Promise(r => setTimeout(r, 2500)); 
        switch (data.level) {
            case 1:
                //this.setState({ fetchStatus: true });
                // await fetchNotaData(data.token, this.updateProgress, this.setError, null);
                // await this.sleep(3000)
                // this.setState({
                //     fetchStatus: false,
                // })
                // if (this.state.progress === 6) {
                    // data.token = this.newToken
                    // this.state.setAuthData(data)
                    this.props.history.push("/FormNota");
                //}
                break;
            case 2:
                this.props.history.push("/TableSupir");
                break;
            case 3:
                this.props.history.push("/TablePerusahaan");
                break;
            default:
                this.props.history.push("/UnknownLevelAccess");
                break;
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            show: false
        })
        if (this.state.username !== "" && this.state.password !== "") {
            this.setState({
                loading: true
            })
            let obj = await hitLogin(this.state.username, this.state.password);
            if (obj[0] !== undefined && obj[0].status === 200) {
                localStorage.clear();
                let data = {
                    token: obj[0].token,
                    level: obj[0].data[0].admin_level,
                    idPerusahaan: obj[0].data[0].perusahaan ? obj[0].data[0].perusahaan._id : "",
                    perusahaan: obj[0].data[0].perusahaan ? obj[0].data[0].perusahaan.nama : "",
                    logo: obj[0].data[0].perusahaan ? obj[0].data[0].perusahaan.logo : ""
                }

                // if(data.level !== 1 && data.level !== "1") this.state.setAuthData(data)
                // else this.newToken = data.token
                this.state.setAuthData(data)
                this.setState({
                    loading: false,
                    success: true
                }, () => {
                    this.redirect(data);
                })
            } else {
                let msg = ""
                if (obj[1] && obj[1].status === 409) msg = "Username / password anda tidak valid."
                else if (obj[1].status === 408) msg = "Request Time Out. Silahkan coba kembali."
                else msg =  "Error. Terjadi kesalahan pada server."
                this.setState({
                    loading: false,
                    show: true,
                    toastMsg: msg
                })
            }
        } else {
            this.setState({
                time: this.getCurrentTime(),
                show: true,
                toastMsg: "Input tidak boleh kosong"
            })
        }
    }
    toggleShow = () => {
        this.setState({
            show: false
        })
    }
    toggle() {
        this.setState({ addClass: !this.state.addClass });
    }
    render() {
        let modalClose = () => this.setState({ modalShow: false });
        let eyeClass = ["eye-logoChild"];
        if (this.state.addClass) {
            eyeClass.push('black');
        }
        return (
            <>
                <div className="box-LoginForm">
                    {/* Trigger modalnya pake button kalo mau liat modalnya. Kalo udah selesai hapus aja buttonnya*/}
                    {/* <Button
                            variant="primary"
                            onClick={() => this.setState({ modalShow: true })}
                        >
                            Launch vertically centered modal
                        </Button>

                        <ModalLoading
                            show={this.state.modalShow}
                            onHide={modalClose}
                        /> */}
                    {/* <Image style={{ position: "absolute", top: "0", left: "0" }} src="assets/pattern/pattern1.png" />
                    <Image style={{ position: "absolute", bottom: "0", right: "0" }} src="assets/pattern/pattern2.png" /> */}
                    <div className="container-LoginForm" >
                        <div className="logo-container">
                            <p className="judul-login">Dashboard</p>
                            <Image className="logo-mkm" src="assets/logo/mkm-transparent.png" />
                        </div>
                        <Form className="form-LoginForm" onSubmit={this.handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <div>
                                    <div className="btn-text">
                                        <Form.Label style={{ fontSize: 16 }}>USERNAME :</Form.Label>
                                    </div>
                                    <div className="btn-input">
                                        <Form.Control name="username" type="text" value={this.state.username} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label style={{ fontSize: 16 }}>PASSWORD  :</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        className="input-Password"
                                        name="password"
                                        type={this.state.hidden ? "password" : "text"}
                                        value={this.state.password}
                                        onChange={this.handlePasswordChange}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon
                                                onClick={() => {
                                                    this.toggle.bind(this);
                                                    this.toggleShowPassword();
                                                }}
                                                icon={this.state.hidden ? faEyeSlash : faEye}
                                            />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                            <div id="btn-container-login">
                                <Button variant="primary" id="btn-loginForm" type="submit" disabled={this.state.success}>
                                    {this.state.loading ? <Spinner animation="border" variant="secondary" /> : (
                                        (!this.state.loading && this.state.success) ? "Sukses" : "Submit"
                                    )}
                                </Button>
                            </div>
                        </Form>
                        {this.state.errorFetch && <p className="login-adminNota">Pengambilan data gagal...</p>}
                        {this.state.fetchStatus && <p className="login-adminNota">Mulai mengambil data...</p>}
                        {this.state.fetchStatus && <ProgressBar animated variant="danger" now={this.state.progress / 6 * 100} />}
                    </div>
                    <div className="container-Toast">
                        <Toast className="box-Toast" show={this.state.show} onClose={this.toggleShow} delay={4000} autohide>
                            <Toast.Body className="Toast-Body" style={{ color: "#f47121" }}>{this.state.toastMsg}</Toast.Body>
                        </Toast>
                    </div>
                </div>
            </>
        )
    }
}

LoginForm.contextType = AuthContext;
export default LoginForm;