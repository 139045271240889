import {Form} from "react-bootstrap";
import React, { Component } from "react";
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'
class FormDataPremiCom extends Component{
    render(){
        return(
                <div className="container">
                    <Form className="form">
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Nama Supir :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Tanggal Pengiriman :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                                    
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >No Polisi Kendaraan :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Tujuan Pengiriman :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Quantity Produk Yang Dibawa  :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Harga Produk :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="RP. 500.000,-" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Total Harga :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="RP. 500.000,-" />
                                </div>
                            </div>                                
                        </Form.Group>                
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Catatan Tambahan  :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control  plaintext readOnly defaultValue="12-12-2020"/>
                                </div>
                            </div>                   
                        </Form.Group>
                        <Form.Group>
                            <div>
                                <div className="btn-text">
                                    <Form.Label >Biaya Premi :</Form.Label>
                                </div>
                                <div className="btn-input">
                                    <Form.Control plaintext readOnly defaultValue="12-12-2020" />
                                </div>
                            </div>                                
                        </Form.Group>                
                    </Form>
                </div>
        )
    }
}

export default FormDataPremiCom;
